import {
  AlertIcon,
  CheckIcon,
  CloseIcon,
  IconButton,
  PencilIcon,
  Spinner,
  TextInput,
  Tooltip,
  TrashIcon,
  classNames,
} from '@movingimage-evp/mi-ui-component-library';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useCurrentUser } from 'src/manager/hooks/current-user';

import {
  AssetUploadStatusCode,
  type Font,
  GetLsproBrandingSettingsDocument as GET_LSPRO_BRANDING_SETTINGS,
  useDeleteLsproCustomFontMutation,
  useUpdateLsproCustomFontMutation,
} from '../../../../generated/graphql-manager';

import styles from './font-list-item.module.css';

type Props = {
  font: Font;
  headingFont: string;
  textFont: string;
  testId: string | number;
};

export function FontListItem({ font, headingFont, textFont, testId }: Props) {
  const { t } = useTranslation();
  const { lsproId } = useCurrentUser();
  const inputRef = useRef<HTMLInputElement>(null);

  const [name, setName] = useState(font.name || font.id);
  const [editMode, setEditMode] = useState(false);

  useEffect(() => {
    if (editMode) inputRef.current?.focus();
  }, [editMode]);

  const [udpateLsproCustomFontMutation] = useUpdateLsproCustomFontMutation();
  const updateCustomFont = (fontId: Font['id'], name: string) => {
    udpateLsproCustomFontMutation({
      variables: {
        input: {
          lsproId,
          customFont: {
            id: fontId,
            name,
          },
        },
      },
      refetchQueries: [{ query: GET_LSPRO_BRANDING_SETTINGS, variables: { lsproId } }],
    });
  };

  const saveChanges = () => {
    updateCustomFont(font.id, name);
    setEditMode(false);
  };

  const cancelEdit = () => {
    setName(font.name || font.id);
    setEditMode(false);
  };

  const [deleteLsproCustomFontMutation, { loading: deleteFontLoading }] = useDeleteLsproCustomFontMutation();
  const deleteCustomFont = (fontId: Font['id']) => {
    deleteLsproCustomFontMutation({
      variables: {
        input: {
          lsproId,
          fontId,
        },
      },
      refetchQueries: [{ query: GET_LSPRO_BRANDING_SETTINGS, variables: { lsproId } }],
    });
  };

  const isPending = font.asset?.status.code === AssetUploadStatusCode.PENDING;
  const isUploaded = font.asset?.status.code === AssetUploadStatusCode.UPLOADED;
  const isFailed = font.asset?.status.code === AssetUploadStatusCode.FAILED;

  const editFontButtonLabel = isPending
    ? t('manager.branding.fontAndTypography.fontList.fontPending')
    : t('manager.branding.fontAndTypography.fontList.editFont');

  const deleteFontButtonLabel =
    font.id === headingFont || font.id === textFont
      ? t('manager.branding.fontAndTypography.fontList.fontInUse')
      : t('manager.branding.fontAndTypography.fontList.deleteFont');

  return (
    <div data-testid="custom-font-list-item" className={classNames(styles.customFont, isPending && styles.isPending)}>
      {isPending && <Spinner size={16} data-testid={`custom-font-pending-spinner-${testId}`} />}

      <TextInput
        data-testid={`custom-font-name-input-${testId}`}
        ref={inputRef}
        readOnly={!editMode}
        value={name}
        onChange={(event) => setName(event.target.value)}
      />

      {isFailed && (
        <>
          <AlertIcon className={styles.failedIcon} data-testid={`custom-font-failed-icon-${testId}`} />
          <span className={styles.failedInfo}>{t('manager.branding.fontAndTypography.fontList.uploadFailed')}</span>
        </>
      )}

      <div className={styles.customFontActions}>
        {editMode && (
          <>
            <Tooltip label={t('manager.branding.fontAndTypography.fontList.saveChanges')}>
              <IconButton
                rounded
                data-testid={`custom-font-name-save-button-${testId}`}
                aria-label={t('manager.branding.fontAndTypography.fontList.saveChanges')}
                disabled={deleteFontLoading}
                onClick={saveChanges}
              >
                <CheckIcon />
              </IconButton>
            </Tooltip>

            <Tooltip label={t('manager.branding.fontAndTypography.fontList.cancelEdit')}>
              <IconButton
                rounded
                aria-label={t('manager.branding.fontAndTypography.fontList.cancelEdit')}
                disabled={deleteFontLoading}
                onClick={cancelEdit}
              >
                <CloseIcon />
              </IconButton>
            </Tooltip>
          </>
        )}

        {!editMode && (
          <>
            {!isFailed && (
              <Tooltip label={editFontButtonLabel}>
                <IconButton
                  rounded
                  data-testid={`custom-font-name-edit-button-${testId}`}
                  aria-label={editFontButtonLabel}
                  disabled={!isUploaded}
                  onClick={() => setEditMode(!editMode)}
                >
                  <PencilIcon />
                </IconButton>
              </Tooltip>
            )}

            <Tooltip label={deleteFontButtonLabel}>
              <IconButton
                rounded
                data-testid={`custom-font-delete-button-${testId}`}
                aria-label={deleteFontButtonLabel}
                disabled={deleteFontLoading || font.id === headingFont || font.id === textFont}
                onClick={() => deleteCustomFont(font.id)}
                style={{ width: 24 }}
              >
                {deleteFontLoading ? <Spinner size={16} /> : <TrashIcon />}
              </IconButton>
            </Tooltip>
          </>
        )}
      </div>
    </div>
  );
}
