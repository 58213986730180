import type {
  GetWebcastSecurity_TokenProtectionPolicyFragment as SecurityPolicies,
  SecurityPolicy,
} from '../../../../generated/graphql-manager';

import { Heading, Paragraph, SelectionInputLayout, Toggle } from '@movingimage-evp/mi-ui-component-library';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

import { RestrictEmbedding } from './restrict-embedding';
import {
  State,
  useGetWebcastEmbeddingQuery,
  useUpdateTokenProtectionPolicyMutation,
} from '../../../../generated/graphql-manager';
import { useAbsoluteRoutes } from '../../../../routes';
import { findTokenProtectionPolicy } from '../../../../utils/graphql-helpers';
import { SetupPageFooter } from '../../../components/setup-page-footer';
import { useUserPermissions } from '../../../hooks/user-permissions';
import managerStyles from '../../../manager.module.css';

import styles from './embedding.module.css';

export type SharingData = {
  sharingUrl: string;
  securedLink: boolean;
  accessCode: string;
};

const DEFAULT_TOKEN_PROTECTION_ENABLED = false;

export function EmbeddingPage() {
  const { t } = useTranslation();
  const { webcastId = '' } = useParams();
  const routes = useAbsoluteRoutes();
  const { isEventEditingAllowed } = useUserPermissions();

  const [isReferrerProtectionOpen, setIsReferrerProtectionOpen] = useState(false);
  const [saving, setSaving] = useState(false);

  const { data, loading } = useGetWebcastEmbeddingQuery({ variables: { webcastId } });

  const securityPolicies = data?.webcast.securityPolicies || [];
  const formDisabled = data?.webcast.state !== State.PRELIVE || !isEventEditingAllowed;
  const tokenProtectionPolicy = findTokenProtectionPolicy(securityPolicies);

  const [tokenProtectionEnabled, setTokenProtectionEnabled] = useState(
    tokenProtectionPolicy?.enabled ?? DEFAULT_TOKEN_PROTECTION_ENABLED
  );

  useEffect(() => {
    if (tokenProtectionPolicy) setTokenProtectionEnabled(tokenProtectionPolicy.enabled);
  }, [tokenProtectionPolicy]);

  const [updateTokenProtectionPolicyMutation] = useUpdateTokenProtectionPolicyMutation();

  const updateTokenProtection = () => {
    setSaving(true);
    setTokenProtectionEnabled(!tokenProtectionEnabled);

    const updatedTokenProtectionPolicy = {
      enabled: !tokenProtectionEnabled,
    };

    return updateTokenProtectionPolicyMutation({
      variables: {
        input: {
          webcastId,
          ...updatedTokenProtectionPolicy,
        },
      },
      optimisticResponse: {
        updateTokenProtectionPolicy: {
          __typename: 'UpdateTokenProtectionSuccess',
          policy: { enabled: !tokenProtectionEnabled },
        },
      },
      update: (cache, { data }) => {
        if (data?.updateTokenProtectionPolicy.__typename === 'UpdateTokenProtectionSuccess') {
          cache.modify({
            id: cache.identify({ __typename: 'Webcast', id: webcastId }),
            fields: {
              securityPolicies: (securityPolicies) => {
                const tokenProtectionPolicyPresent =
                  securityPolicies.find((policy: SecurityPolicies) => policy.__typename === 'TokenProtectionPolicy') !==
                  undefined;

                if (tokenProtectionPolicyPresent) {
                  return securityPolicies.map((policy: SecurityPolicies) =>
                    policy.__typename === 'TokenProtectionPolicy'
                      ? { ...policy, ...updatedTokenProtectionPolicy }
                      : policy
                  );
                } else {
                  return [...securityPolicies, updatedTokenProtectionPolicy];
                }
              },
            },
          });
        }
      },
      onCompleted: () => {
        setSaving(false);
      },
    });
  };

  return (
    <main className={managerStyles.main} data-testid="sharing-page">
      <Heading className={managerStyles.grayText}>{t('manager.webcastSetup.embedding.title')}</Heading>
      <Paragraph className={managerStyles.grayText}>{t('manager.webcastSetup.embedding.description')}</Paragraph>

      <div className={styles.embeddingWrapper}>
        {!loading && (
          <RestrictEmbedding
            webcastId={webcastId}
            disabled={formDisabled}
            securityPolicies={securityPolicies as SecurityPolicy[]}
            isOpen={isReferrerProtectionOpen}
            onToggle={setIsReferrerProtectionOpen}
            onSaving={setSaving}
          />
        )}

        <SelectionInputLayout
          hintMessage={t('manager.webcastSetup.embedding.tokenProtection.hintMessage')}
          className={styles.inputLayout}
          checked={tokenProtectionEnabled}
          disabled={formDisabled}
          variant="ghost"
          inputElement={
            <Toggle
              data-testid="enable-token-authorization-toggle"
              checked={tokenProtectionEnabled}
              disabled={formDisabled}
              onChange={updateTokenProtection}
            >
              {t('manager.webcastSetup.embedding.tokenProtection.label')}
            </Toggle>
          }
        />
      </div>

      <SetupPageFooter
        nextLabel={t('manager.webcastSetup.embedding.nextStep.label')}
        route={routes.webcastSetup_messaging}
        saving={saving}
      />
    </main>
  );
}
