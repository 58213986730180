import type { WebcastWithTitle } from '../../manager/pages/webcasts/types';
import type { HTMLAttributes, KeyboardEvent, MouseEvent } from 'react';

import { classNames } from '@movingimage-evp/mi-ui-component-library';
import { useNavigate } from 'react-router';

import { State } from '../../generated/graphql-manager';
import { useUserPermissions } from '../../manager/hooks/user-permissions';
import { ActionButtons } from '../../manager/pages/webcasts/views/action-buttons/action-buttons';
import { ONDEMAND_STATE } from '../../manager/pages/webcasts/webcasts';
import { useAbsoluteRoutes } from '../../routes';
import { addParamToPath, formatEventStartDate } from '../../utils';
import { StateBadge } from '../state-badge';

import styles from './webcast-card.module.css';

type Props = {
  webcast: WebcastWithTitle;
  onDelete: () => void;
  onDuplicate: () => void;
} & HTMLAttributes<HTMLElement>;

export function WebcastCard({ webcast, onDelete, onDuplicate, ...props }: Props) {
  const navigate = useNavigate();
  const routes = useAbsoluteRoutes();
  const { isAccessToEventSetupAllowed } = useUserPermissions();
  const { id, title, state, plannedStartAt, coverImageUrl } = webcast;

  const isDefaultThumbnail = coverImageUrl?.endsWith('/defaultCoverImage');
  const isInitializing = state === State.INITIALIZING;

  const handleNavigation = () => {
    if (!isAccessToEventSetupAllowed) {
      return navigate(addParamToPath(routes.operator_messaging, id));
    }

    if (state === State.POSTLIVE || state === ONDEMAND_STATE) {
      return navigate(addParamToPath(routes.eventSummary, id));
    }

    navigate(addParamToPath(routes.webcastSetup_setupSummary, id));
  };

  const handleCardClick = (event: MouseEvent) => {
    if (event.target instanceof HTMLButtonElement) return;

    handleNavigation();
  };

  const handleCardKeyDown = (event: KeyboardEvent) => {
    if (event.key !== 'Enter') return;

    handleNavigation();
  };

  return (
    <div
      role="button"
      data-testid="webcast-card"
      tabIndex={0}
      className={classNames(styles.wrapper, isInitializing && styles.cardDisabled)}
      onClick={handleCardClick}
      onKeyDown={handleCardKeyDown}
      {...props}
    >
      <div
        className={classNames(styles.thumbnail, isDefaultThumbnail && styles.defaultThumbnail)}
        data-testid="webcast-card-thumbnail"
        style={coverImageUrl ? { backgroundImage: `url(${coverImageUrl})` } : undefined}
      />

      <div className={styles.content}>
        <span className={styles.startDate} data-testid="webcast-card-date">
          {formatEventStartDate(plannedStartAt)}
        </span>

        <span className={styles.title} data-testid="webcast-card-title">
          {title}
        </span>
      </div>

      <footer>
        <StateBadge state={state} />
        <ActionButtons webcast={webcast} onDeleteWebcast={onDelete} onDuplicateWebcast={onDuplicate} />
      </footer>
    </div>
  );
}
