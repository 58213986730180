import { Accordion, CopyToClipboard, InputDescription, TextInput } from '@movingimage-evp/mi-ui-component-library';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

import { IngestProtocol, useGetWebcastSetupSummaryQuery } from '../../../../generated/graphql-manager';
import { generateSrtUrl, getLanguageKey } from '../../../../utils';

import styles from './setup-summary.module.css';

export function EncoderInfo() {
  const { webcastId = '' } = useParams();
  const { t } = useTranslation();

  const { data } = useGetWebcastSetupSummaryQuery({ variables: { webcastId }, skip: !webcastId });
  const { primaryLanguage, additionalLanguages, streaming } = data?.webcast || {};
  const ingestProtocol = streaming?.live?.ingestProtocol;
  const streams = streaming?.live?.streams;
  const primaryStream = streams ? streams[0] : undefined;
  const { primaryServer, backupServer, streamName, username, password } = primaryStream?.ingest || {};
  const hasRtmpProtocol = ingestProtocol === IngestProtocol.RTMP;
  const serverUrl = hasRtmpProtocol ? primaryServer : generateSrtUrl(primaryServer, password);
  const backupServerUrl = hasRtmpProtocol ? backupServer : backupServer && generateSrtUrl(backupServer, password);
  const isMultipleLanguages = hasRtmpProtocol && Boolean(additionalLanguages?.length);
  const multipleLanguages = [primaryLanguage, ...(additionalLanguages || [])];
  const streamingParameters = data?.webcast?.streaming?.live?.parameters;
  const rtmpAuthenticationEnabled =
    streamingParameters?.__typename === 'RtmpLiveStreamingParameters' && streamingParameters?.authenticationEnabled;

  let label = '';
  if (ingestProtocol === IngestProtocol.RTMP) label = t('manager.webcastSetup.protocolSettings.options.rtmps.label');
  if (ingestProtocol === IngestProtocol.SRT) label = t('manager.webcastSetup.protocolSettings.options.srt.label');

  return (
    <Accordion
      data-testid="encoder-info-accordion"
      buttonContent={
        <div className={styles.accordionLabel}>
          <span>{t('manager.webcastSetup.summary.encoder.title')}</span>
          <span data-testid="encoder-info-label">{label}</span>
        </div>
      }
    >
      <span className={styles.sectionTitle}>{t('manager.webcastSetup.summary.encoder.streamingServer.title')}</span>

      {serverUrl && (
        <InputDescription
          inputId="server-url"
          label={t('manager.webcastSetup.summary.encoder.streamingServer.serverUrlLabel')}
        >
          <TextInput readOnly value={serverUrl} data-testid="server-url">
            <CopyToClipboard value={serverUrl} notificationPosition="left" />
          </TextInput>
        </InputDescription>
      )}

      {backupServerUrl && (
        <InputDescription
          inputId="backup-server-url"
          label={t('manager.webcastSetup.summary.encoder.streamingServer.backupServerUrlLabel')}
        >
          <TextInput readOnly value={backupServerUrl} data-testid="backup-server-url">
            <CopyToClipboard value={backupServerUrl} notificationPosition="left" />
          </TextInput>
        </InputDescription>
      )}

      {streamName && hasRtmpProtocol && !isMultipleLanguages && (
        <InputDescription
          inputId="streaming-key"
          label={t('manager.webcastSetup.summary.encoder.streamingServer.streamingKeyLabel')}
        >
          <TextInput readOnly value={streamName} data-testid="streaming-key">
            <CopyToClipboard value={streamName} notificationPosition="left" />
          </TextInput>
        </InputDescription>
      )}

      {isMultipleLanguages && (
        <span className={styles.sectionTitle}>{t('manager.webcastSetup.summary.encoder.languagesKeys.title')}</span>
      )}

      {isMultipleLanguages &&
        multipleLanguages.map((language) => {
          const streamName = streams?.find((stream) => stream.metadata?.language === language)?.ingest.streamName;

          if (!streamName || !language) return null;

          return (
            <InputDescription
              key={`streaming-key-${language}`}
              inputId={`streaming-key-${language}`}
              label={t(`common.languages.${getLanguageKey(language)}`)}
            >
              <TextInput readOnly value={streamName} data-testid={`streaming-key-${language}`}>
                <CopyToClipboard value={streamName} notificationPosition="left" />
              </TextInput>
            </InputDescription>
          );
        })}

      {hasRtmpProtocol && rtmpAuthenticationEnabled && (
        <>
          <span className={styles.sectionTitle}>{t('manager.webcastSetup.summary.encoder.authentication.title')}</span>

          {username && (
            <InputDescription
              inputId="username"
              label={t('manager.webcastSetup.summary.encoder.authentication.usernameLabel')}
            >
              <TextInput readOnly value={username} data-testid="username">
                <CopyToClipboard value={username} notificationPosition="left" />
              </TextInput>
            </InputDescription>
          )}

          {password && (
            <InputDescription
              inputId="password"
              label={t('manager.webcastSetup.summary.encoder.authentication.passwordLabel')}
            >
              <TextInput readOnly value={password} data-testid="password">
                <CopyToClipboard value={password} notificationPosition="left" />
              </TextInput>
            </InputDescription>
          )}
        </>
      )}
    </Accordion>
  );
}
