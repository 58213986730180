import type { CreateWebcastInput, GetWebcastQuery } from '../../../../generated/graphql-manager';
import type { FormEvent } from 'react';

import {
  DatePicker,
  type DatePickerProps,
  Heading,
  InputDescription,
  Paragraph,
  Select,
  type SelectOption,
  TextInput,
} from '@movingimage-evp/mi-ui-component-library';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router';

import { DURATIONS, MINUTES, generateNextWholeHourFromNow } from './event-details.helpers';
import { eventDetailsValidation } from './event-details.validation';
import { isPassedTime } from './is-passed-time';
import {
  State,
  useCreateWebcastMutation,
  useGetWebcastQuery,
  useUpdateWebcastMutation,
} from '../../../../generated/graphql-manager';
import { getLocale } from '../../../../i18n';
import { useAbsoluteRoutes } from '../../../../routes';
import { addParamToPath, generateArrayOfNumbers } from '../../../../utils';
import { SetupPageFooter } from '../../../components/setup-page-footer';
import { useCurrentUser } from '../../../hooks/current-user';
import { getWebcastTitle } from '../../../hooks/use-get-primary-title';
import { useUserPermissions } from '../../../hooks/user-permissions';
import managerStyles from '../../../manager.module.css';

import styles from './event-details.module.css';

type Webcast = GetWebcastQuery['webcast'];

export type WebcastData = {
  title: string;
  durationInMinutes?: string;
  startingHour?: string;
  startingMinutes?: string;
  date?: Date;
};

type WebcastDataToSend = Omit<CreateWebcastInput, 'ingestProtocol' | 'lsproId'>;

export type ValidationErrors = {
  title?: string;
  date?: string;
  durationInMinutes?: string;
  startingHour?: string;
  startingMinutes?: string;
};

const defaultWebcast: WebcastData = {
  title: '',
  durationInMinutes: '60',
  startingHour: generateNextWholeHourFromNow().toString(),
  startingMinutes: '0',
  date: new Date(),
};

const alignedWebcastDataFromBackend = (webcastDataFromBackend: Webcast): WebcastData => {
  const { plannedStartAt, primaryLanguage, contents, durationInMinutes } = webcastDataFromBackend;
  const date = new Date(plannedStartAt);
  const timeInHours = date.getHours().toString();
  const timeInMinutes = date.getMinutes().toString();
  date.setHours(0, 0, 0);

  return {
    title: getWebcastTitle(primaryLanguage, contents) || '',
    durationInMinutes: String(durationInMinutes),
    startingHour: timeInHours,
    startingMinutes: timeInMinutes,
    date,
  };
};

export function EventDetailsPage() {
  const { webcastId = '' } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { lsproId } = useCurrentUser();
  const { isEventEditingAllowed } = useUserPermissions();
  const routes = useAbsoluteRoutes();
  const locale = getLocale();

  const currentDate = new Date();
  const currentHour = currentDate.getHours();
  const currentMinutes = currentDate.getMinutes();

  const startingHourForToday = !currentMinutes ? currentHour : currentHour + 1;
  const [availableHours, setAvailableHours] = useState(generateArrayOfNumbers(23, startingHourForToday));

  const getStartingHour = (startingSelectedHour?: string) => {
    return startingSelectedHour !== undefined && Number(startingSelectedHour) < startingHourForToday
      ? Number(startingSelectedHour)
      : startingHourForToday;
  };

  const checkAvailableHours = (selectedDate: Date, startingSelectedHour?: string) => {
    const startingHour = getStartingHour(startingSelectedHour);

    if (selectedDate.getDate() === currentDate.getDate()) {
      setAvailableHours(generateArrayOfNumbers(23, startingHour));
    } else {
      setAvailableHours(generateArrayOfNumbers(23));
    }
  };

  const webcastQueryResponse = useGetWebcastQuery({
    variables: { webcastId },
    skip: !webcastId,
    onCompleted: (data) => {
      if (data.webcast) {
        const formatedWebcast = alignedWebcastDataFromBackend(data.webcast);
        if (formatedWebcast.date) checkAvailableHours(formatedWebcast.date, formatedWebcast.startingHour);
      }
    },
  });

  const webcast = webcastQueryResponse.data?.webcast;

  const [webcastFormData, setWebcastFormData] = useState(
    webcast ? alignedWebcastDataFromBackend(webcast) : defaultWebcast
  );

  const [formErrors, setFormErrors] = useState<ValidationErrors>({});
  const [savingFailed, setSavingFailed] = useState(false);

  const isDataReadyToBeDisplayed = webcastId !== '' && webcastFormData.title === '' && webcast === undefined;
  const pastDatesDisallowed =
    webcast?.state === State.PRELIVE || webcast?.state === State.LIVE_TEST || webcast?.state === undefined;

  useEffect(() => {
    if (webcast) {
      setWebcastFormData(alignedWebcastDataFromBackend(webcast));
    }
  }, [webcast]);

  const [createWebcastMutation, createWebcastMutationResponse] = useCreateWebcastMutation();

  const createWebcast = (newWebcast: WebcastDataToSend) => {
    createWebcastMutation({
      variables: { input: { ...newWebcast, lsproId } },
      onCompleted: (data) => {
        if (data.createWebcast.__typename !== 'CreateWebcastSuccess') {
          setSavingFailed(true);
          return;
        }

        setSavingFailed(false);
        navigate(addParamToPath(routes.webcastSetup_eventDetails, data.createWebcast.webcast.id));
      },
    });
  };

  const [updateWebcastMutation, updateWebcastMutationResponse] = useUpdateWebcastMutation();

  const updateWebcast = (updatedWebcast: WebcastDataToSend) => {
    if (!webcast) return;

    const updatedWebcastData = {
      id: webcastId,
      durationInMinutes: updatedWebcast.durationInMinutes,
      plannedStartAt: updatedWebcast.plannedStartAt,
      contents: [{ title: updatedWebcast.title, language: webcast.primaryLanguage }],
    };

    updateWebcastMutation({
      variables: { input: updatedWebcastData },
      optimisticResponse: {
        updateWebcast: {
          __typename: 'UpdateWebcastSuccess',
          webcast: {
            __typename: 'Webcast',
            lsproId,
            ...updatedWebcastData,
            primaryLanguage: webcast.primaryLanguage,
          },
        },
      },
      onCompleted: (data) => {
        setSavingFailed(data.updateWebcast.__typename !== 'UpdateWebcastSuccess');
      },
    });
  };

  const onWebcastDataChange = (value: string, name: string) => {
    setWebcastFormData({ ...webcastFormData, [name]: value });
    setFormErrors({ ...formErrors, [name]: undefined });
  };

  const onDataPickerChange = (value: DatePickerProps['value']) => {
    if (!(value instanceof Date)) return;

    if (value) checkAvailableHours(value);

    if (
      value &&
      webcastFormData.startingHour &&
      webcastFormData.startingMinutes &&
      isPassedTime(value, Number(webcastFormData.startingHour), Number(webcastFormData.startingMinutes), new Date())
    ) {
      setWebcastFormData({ ...webcastFormData, date: value, startingHour: getStartingHour().toString() });
    } else {
      setWebcastFormData({ ...webcastFormData, date: value });
    }

    setFormErrors({ ...formErrors, date: undefined });
  };

  const handleOnSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const validationErrors: ValidationErrors = eventDetailsValidation(webcastFormData, pastDatesDisallowed);

    setFormErrors(validationErrors);

    if (Object.keys(validationErrors).length !== 0) {
      return;
    }

    if (
      webcastFormData.date &&
      webcastFormData.startingHour &&
      webcastFormData.startingMinutes &&
      webcastFormData.durationInMinutes
    ) {
      const webcastDataToSubmit: WebcastDataToSend = {
        title: webcastFormData.title,
        plannedStartAt: new Date(
          webcastFormData.date.setHours(
            Number(webcastFormData.startingHour),
            Number(webcastFormData.startingMinutes),
            0
          )
        ).toISOString(),
        durationInMinutes: Number(webcastFormData.durationInMinutes),
      };

      if (webcastId) {
        updateWebcast(webcastDataToSubmit);
      } else {
        createWebcast(webcastDataToSubmit);
      }
    }
  };

  const changesLoading = createWebcastMutationResponse.loading || updateWebcastMutationResponse.loading;

  if (isDataReadyToBeDisplayed) return null;

  return (
    <main className={managerStyles.main} data-testid="event-details-page">
      <Heading className={managerStyles.grayText}>{t('manager.webcastSetup.eventDetails.pageTitle')}</Heading>
      <Paragraph className={managerStyles.grayText}>{t('manager.webcastSetup.eventDetails.description')}</Paragraph>

      <form id="event-details-form" onSubmit={handleOnSubmit}>
        <fieldset className={styles.fieldSet} disabled={!isEventEditingAllowed}>
          <InputDescription
            inputId="title"
            errorMessage={formErrors.title}
            label={t('manager.webcastSetup.eventDetails.title.label')}
          >
            <TextInput
              data-testid="title"
              placeholder={t('manager.webcastSetup.eventDetails.title.placeholder')}
              autoFocus={!webcastId}
              value={webcastFormData.title}
              onChange={(event) => onWebcastDataChange(event.target.value, 'title')}
            />
          </InputDescription>

          <InputDescription
            inputId="date-picker"
            label={t('manager.webcastSetup.eventDetails.date.label')}
            errorMessage={formErrors.date}
          >
            <DatePicker
              disablePastDays={pastDatesDisallowed}
              value={webcastFormData.date}
              onChange={onDataPickerChange}
              showCalendarIcon
              locale={locale}
              disabled={!isEventEditingAllowed}
            />
          </InputDescription>

          <div className={styles.dropdowns}>
            <InputDescription
              inputId="starting-hour"
              label={t('manager.webcastSetup.eventDetails.time.hour.label')}
              errorMessage={formErrors.startingHour}
            >
              <Select
                data-testid="starting-hour"
                placeholder={t('manager.webcastSetup.eventDetails.time.hour.placeholder')}
                disabled={!isEventEditingAllowed}
                optionsIdProperty="key"
                value={{ key: webcastFormData.startingHour }}
                options={availableHours.map((hour) => ({ key: String(hour) }))}
                onChange={(option: SelectOption) => onWebcastDataChange(option.key, 'startingHour')}
              />
            </InputDescription>

            <InputDescription
              label={t('manager.webcastSetup.eventDetails.time.minutes.label')}
              inputId="starting-minutes"
              errorMessage={formErrors.startingMinutes}
            >
              <Select
                data-testid="starting-minutes"
                placeholder={t('manager.webcastSetup.eventDetails.time.minutes.placeholder')}
                disabled={!isEventEditingAllowed}
                optionsIdProperty="key"
                value={{
                  key: webcastFormData.startingMinutes,
                  label: MINUTES.find(({ value }) => value === webcastFormData.startingMinutes)?.label,
                }}
                options={MINUTES.map(({ value, label }) => ({ key: value, label }))}
                onChange={(option: SelectOption) => onWebcastDataChange(option.key, 'startingMinutes')}
              />
            </InputDescription>

            <InputDescription
              label={t('manager.webcastSetup.eventDetails.duration.placeholder')}
              inputId="duration-in-minutes"
              errorMessage={formErrors.durationInMinutes}
            >
              <Select
                data-testid="duration-in-minutes"
                placeholder={t('manager.webcastSetup.eventDetails.duration.placeholder')}
                disabled={!isEventEditingAllowed}
                optionsIdProperty="key"
                value={{
                  key: webcastFormData.durationInMinutes,
                  label: DURATIONS.find(({ value }) => value === Number(webcastFormData.durationInMinutes))?.label,
                }}
                options={DURATIONS.map(({ value, label }) => ({ key: String(value), label }))}
                onChange={(option: SelectOption) => onWebcastDataChange(option.key, 'durationInMinutes')}
              />
            </InputDescription>
          </div>
        </fieldset>
      </form>

      <SetupPageFooter
        nextLabel={t('manager.webcastSetup.eventDetails.nextStep.label')}
        route={routes.webcastSetup_eventPermissions}
        showActiveSaveButton
        nextButtonDisabled={!webcast}
        saveButtonDisabled={!isEventEditingAllowed}
        saving={changesLoading}
        savingFailed={savingFailed}
      />
    </main>
  );
}
