import type { SelectOption } from '@movingimage-evp/mi-ui-component-library';
import type { EventPermission } from 'src/generated/graphql-manager';

import {
  InputDescription,
  Modal,
  Note,
  PrimaryButton,
  Radio,
  SecondaryButton,
  Select,
  SelectionInputLayout,
  Spinner,
} from '@movingimage-evp/mi-ui-component-library';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import {
  GetWebcastPermissionsDocument as GET_EVENT_PERMISSIONS,
  ItemListAction,
  useGetUsersQuery,
  useManageEventPermissionsMutation,
} from 'src/generated/graphql-manager';
import { useCurrentUser } from 'src/manager/hooks/current-user';

import { UserPermissionType } from '../event-permissions';

import styles from './permissions-manager.module.css';

type Props = {
  owners: EventPermission['owners'];
  collaborators: EventPermission['collaborators'];
  isOpen: boolean;
  onClose: () => void;
};

export function PermissionsManager({ owners, collaborators, isOpen, onClose }: Props) {
  const { t } = useTranslation();
  const { lsproId } = useCurrentUser();
  const { webcastId = '' } = useParams();
  const [selectedUsers, setSelectedUsers] = useState<string[]>([]);
  const [userPermissionType, setUserPermissionType] = useState<UserPermissionType>(UserPermissionType.COLLABORATORS);

  const getUsersResponse = useGetUsersQuery({ variables: { lsproId } });
  const users = getUsersResponse.data?.users.__typename === 'GetUsersSuccess' ? getUsersResponse.data.users.users : [];
  const existingUsers = userPermissionType === UserPermissionType.COLLABORATORS ? collaborators : owners;
  const filteredUsers = users.filter((user) => !existingUsers.some(({ id }) => id === user.id));

  const [updateUserPermissions, { loading }] = useManageEventPermissionsMutation();

  const handleInviteUsers = () => {
    updateUserPermissions({
      variables: {
        input: {
          webcastId,
          [userPermissionType]: {
            items: selectedUsers,
            action: ItemListAction.ADD,
          },
        },
      },
      refetchQueries: [{ query: GET_EVENT_PERMISSIONS, variables: { webcastId } }],
      onCompleted: onClose,
    });
  };

  return (
    <Modal
      data-testid="permissions-manager-modal"
      title={t('manager.webcastSetup.eventPermissions.permissionManager.title')}
      isOpen={isOpen}
      onClose={onClose}
      className={styles.modalContainer}
      footer={
        <>
          <SecondaryButton data-testid="cancel-invite-people-button" type="button" onClick={onClose}>
            {t('common.cancel')}
          </SecondaryButton>

          <PrimaryButton data-testid="invite-people-button" onClick={handleInviteUsers}>
            {loading ? <Spinner /> : t('manager.webcastSetup.eventPermissions.permissionManager.inviteButton')}
          </PrimaryButton>
        </>
      }
    >
      <div className={styles.content}>
        <Note variant="primary" data-testid="invite-people-note" className={styles.note}>
          {t('manager.webcastSetup.eventPermissions.permissionManager.note')}
        </Note>

        <InputDescription label={t('manager.webcastSetup.eventPermissions.permissionManager.usersSelect')}>
          <Select
            data-testid="channel-users"
            placeholder={t('manager.webcastSetup.eventPermissions.permissionManager.usersSelect')}
            optionsIdProperty="key"
            isSearchable
            value={selectedUsers?.map((user) => ({
              key: user,
              label: users?.find((u) => u.id === user)?.email,
            }))}
            options={filteredUsers
              ?.sort((a, b) => (a.email > b.email ? 1 : -1))
              ?.map((user) => ({
                key: user.id,
                label: user.email,
              }))}
            onChange={(options) => setSelectedUsers(options.map((option: SelectOption) => option.key))}
          />
        </InputDescription>

        <SelectionInputLayout
          checked={userPermissionType === UserPermissionType.COLLABORATORS}
          hintMessage={t('manager.webcastSetup.eventPermissions.permissionManager.options.collaborators.description')}
          inputElement={
            <Radio
              data-testid="collaborators-event-permission"
              name="user-permission"
              value={UserPermissionType.COLLABORATORS}
              checked={userPermissionType === UserPermissionType.COLLABORATORS}
              onChange={() => {
                setUserPermissionType(UserPermissionType.COLLABORATORS);
              }}
            >
              {t('manager.webcastSetup.eventPermissions.permissionManager.options.collaborators.label')}
            </Radio>
          }
        />

        <SelectionInputLayout
          checked={userPermissionType === UserPermissionType.OWNERS}
          hintMessage={t('manager.webcastSetup.eventPermissions.permissionManager.options.owners.description')}
          inputElement={
            <Radio
              data-testid="owners-event-permission"
              name="user-permission"
              value={UserPermissionType.OWNERS}
              checked={userPermissionType === UserPermissionType.OWNERS}
              onChange={() => {
                setUserPermissionType(UserPermissionType.OWNERS);
              }}
            >
              {t('manager.webcastSetup.eventPermissions.permissionManager.options.owners.label')}
            </Radio>
          }
        />
      </div>
    </Modal>
  );
}
