import type { ReactNode } from 'react';

import { createContext, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router';

import { useViewerAccess } from './viewer-access-provider';
import { useGetWebcastStreamQuery, useGetWebcastViewerQuery } from '../../generated/graphql-viewer';
import { useAbsoluteRoutes } from '../../routes';

type LanguageContextValue = {
  availableLanguages: string[] | undefined;
  selectedLanguage: string | undefined;
  setSelectedLanguage: (language: string) => void;
};

const initialLanguageContext: LanguageContextValue = {
  availableLanguages: undefined,
  selectedLanguage: undefined,
  setSelectedLanguage: () => {},
};

export const LanguageContext = createContext<LanguageContextValue>(initialLanguageContext);

type Props = {
  children: ReactNode;
};

export function SelectedLanguageProvider({ children }: Props) {
  const { i18n } = useTranslation();
  const absoluteRoutes = useAbsoluteRoutes();
  const { email, accessCode, protectionToken } = useViewerAccess();
  const { webcastId = '', language: urlLanguage } = useParams();
  const navigate = useNavigate();

  const { data: getWebcastViewerResponse } = useGetWebcastViewerQuery({
    variables: { webcastId, accessCode, email, protectionToken },
    skip: !webcastId,
  });
  const { data: getWebcastStreamResponse } = useGetWebcastStreamQuery({
    variables: { webcastId, accessCode, email, protectionToken },
    skip: !webcastId,
  });

  const primaryLanguage = getWebcastViewerResponse?.webcast?.primaryLanguage;
  const additionalLanguages = getWebcastViewerResponse?.webcast?.additionalLanguages;
  const vod = getWebcastStreamResponse?.webcast?.streaming?.vod;
  const vodLanguages = vod?.streams?.map(({ metadata }) => metadata.language || '') || [];
  const eventLanguages = [...(primaryLanguage ? [primaryLanguage] : []), ...(additionalLanguages || [])];
  const availableLanguages = primaryLanguage ? (vod?.enabled ? vodLanguages : eventLanguages) : undefined;

  const [selectedLanguage, setSelectedLanguage] = useState(urlLanguage || primaryLanguage);
  const selectedLanguageIsValid = selectedLanguage !== undefined && availableLanguages?.includes(selectedLanguage);

  useEffect(() => {
    if (!primaryLanguage) return;

    const language = selectedLanguageIsValid ? selectedLanguage : primaryLanguage;

    i18n.changeLanguage(language);
    setSelectedLanguage(language);

    const urlSearchParams = new URLSearchParams(window.location.search);
    const { code } = Object.fromEntries(urlSearchParams.entries());

    if (!code) navigate(`${absoluteRoutes.viewer}/${webcastId}/${language}`);
  }, [i18n, webcastId, primaryLanguage, selectedLanguage, absoluteRoutes.viewer, selectedLanguageIsValid, navigate]);

  return (
    <LanguageContext.Provider value={{ availableLanguages, selectedLanguage, setSelectedLanguage }}>
      {children}
    </LanguageContext.Provider>
  );
}

export const useSelectedLanguage = () => useContext(LanguageContext);
