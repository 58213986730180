import { Heading, Paragraph, Radio, SelectionInputLayout, classNames } from '@movingimage-evp/mi-ui-component-library';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

import {
  ChatType,
  State,
  useChangeChatTypeMutation,
  useCreateChatMutation,
  useDeleteChatMutation,
  useGetChatTypeQuery,
} from '../../../../generated/graphql-manager';
import { useAbsoluteRoutes } from '../../../../routes';
import { SetupPageFooter } from '../../../components/setup-page-footer';
import { useUserPermissions } from '../../../hooks/user-permissions';
import managerStyles from '../../../manager.module.css';

import styles from './messaging.module.css';

export function MessagingPage() {
  const { webcastId = '' } = useParams();
  const { t } = useTranslation();
  const routes = useAbsoluteRoutes();
  const { isEventEditingAllowed } = useUserPermissions();

  const getChatTypeQueryResponse = useGetChatTypeQuery({ variables: { webcastId } });
  const formDisabled = getChatTypeQueryResponse.data?.webcast?.state !== State.PRELIVE || !isEventEditingAllowed;
  const chatType = getChatTypeQueryResponse.data?.webcast?.chat?.type;
  const loading = getChatTypeQueryResponse.loading;

  const [createChatMutation, { loading: createChatTypeLoading }] = useCreateChatMutation();

  const createChat = (type: ChatType) => {
    createChatMutation({
      variables: { input: { webcastId, type } },
      optimisticResponse: { createChat: { __typename: 'CreateChatSuccess' } },
      update: (cache, { data }) => {
        if (data?.createChat.__typename !== 'CreateChatSuccess') return;

        cache.modify({
          id: cache.identify({ __typename: 'Webcast', id: webcastId }),
          fields: { chat: (chat) => ({ ...chat, type }) },
        });
      },
    });
  };

  const [changeChatTypeMutation, { loading: changeChatTypeLoading }] = useChangeChatTypeMutation();

  const changeChatType = (type: ChatType) => {
    changeChatTypeMutation({
      variables: { input: { webcastId, type } },
      optimisticResponse: { changeChatType: { __typename: 'ChangeChatTypeSuccess' } },
      update: (cache, { data }) => {
        if (data?.changeChatType.__typename !== 'ChangeChatTypeSuccess') return;

        cache.modify({
          id: cache.identify({ __typename: 'Webcast', id: webcastId }),
          fields: { chat: (chat) => ({ ...chat, type }) },
        });
      },
    });
  };

  const [deleteChatMutation, { loading: deleteChatTypeLoading }] = useDeleteChatMutation();

  const deleteChat = () => {
    deleteChatMutation({
      variables: { webcastId },
      optimisticResponse: { deleteChat: { __typename: 'DeleteChatSuccess' } },
      update: (cache, { data }) => {
        if (data?.deleteChat.__typename !== 'DeleteChatSuccess') return;

        cache.modify({
          id: cache.identify({ __typename: 'Webcast', id: webcastId }),
          fields: { chat: () => null },
        });
      },
    });
  };

  const handleChangeChatType = (newType?: ChatType) => () => {
    if (newType)
      if (chatType) changeChatType(newType);
      else createChat(newType);
    else deleteChat();
  };

  return (
    <main className={classNames(managerStyles.main, styles.wrapper)} data-testid="messaging-page">
      <Heading className={managerStyles.grayText}>{t('manager.webcastSetup.messaging.title')}</Heading>
      <Paragraph className={managerStyles.grayText}>{t('manager.webcastSetup.messaging.description')}</Paragraph>

      <div className={styles.options}>
        <SelectionInputLayout
          checked={chatType === undefined}
          disabled={formDisabled || loading}
          inputElement={
            <Radio
              data-testid="disabled-chat-option"
              name="chat-type"
              value={undefined}
              checked={chatType === undefined}
              disabled={formDisabled || loading}
              onChange={handleChangeChatType(undefined)}
            >
              {t('manager.webcastSetup.messaging.options.disabledChat.label')}
            </Radio>
          }
        />

        <SelectionInputLayout
          hintMessage={t('manager.webcastSetup.messaging.options.unmoderatedChat.description')}
          checked={chatType === ChatType.UNMODERATED}
          disabled={formDisabled || loading}
          inputElement={
            <Radio
              data-testid="unmoderated-chat-option"
              name="chat-type"
              value={ChatType.UNMODERATED}
              checked={chatType === ChatType.UNMODERATED}
              disabled={formDisabled || loading}
              onChange={handleChangeChatType(ChatType.UNMODERATED)}
            >
              {t('manager.webcastSetup.messaging.options.unmoderatedChat.label')}
            </Radio>
          }
        />

        <SelectionInputLayout
          hintMessage={t('manager.webcastSetup.messaging.options.moderatedChat.description')}
          checked={chatType === ChatType.MODERATED}
          disabled={formDisabled || loading}
          inputElement={
            <Radio
              data-testid="moderated-chat-option"
              name="chat-type"
              value={ChatType.MODERATED}
              checked={chatType === ChatType.MODERATED}
              disabled={formDisabled || loading}
              onChange={handleChangeChatType(ChatType.MODERATED)}
            >
              {t('manager.webcastSetup.messaging.options.moderatedChat.label')}
            </Radio>
          }
        />

        <SelectionInputLayout
          hintMessage={t('manager.webcastSetup.messaging.options.anonymousChat.description')}
          checked={chatType === ChatType.ANONYMOUS}
          disabled={formDisabled || loading}
          inputElement={
            <Radio
              data-testid="anonymous-chat-option"
              name="chat-type"
              value={ChatType.ANONYMOUS}
              checked={chatType === ChatType.ANONYMOUS}
              disabled={formDisabled || loading}
              onChange={handleChangeChatType(ChatType.ANONYMOUS)}
            >
              {t('manager.webcastSetup.messaging.options.anonymousChat.label')}
            </Radio>
          }
        />
      </div>

      <SetupPageFooter
        nextLabel={t('manager.webcastSetup.messaging.nextStep.label')}
        route={routes.webcastSetup_eventRecording}
        saving={createChatTypeLoading || changeChatTypeLoading || deleteChatTypeLoading}
      />
    </main>
  );
}
