import { useLocation } from 'react-router';

import { useAbsoluteRoutes } from '../../../routes';
import { useCurrentUser } from '../current-user';

export function useUserPermissions(channelId?: string) {
  const { isAdminUser, isEditorUser, isProducerUser, isModeratorUser } = useCurrentUser(channelId);
  const { pathname } = useLocation();
  const routes = useAbsoluteRoutes();

  const isEventDeletionAllowed = isAdminUser || isEditorUser;
  const isEventDuplicationAllowed = isAdminUser || isEditorUser;
  const isEventEditingAllowed = isAdminUser || isEditorUser;
  const isEventCreationAllowed = isAdminUser || isEditorUser;
  const isEventReportingAllowed = isAdminUser || isEditorUser;

  const isAccessToUserPageAllowed = isAdminUser;
  const isAccessToChannelSettingsAllowed = isAdminUser;

  const isAccessToEventSetupAllowed = isAdminUser || isEditorUser || isProducerUser;
  const isEditorAndModerator = isEditorUser && isModeratorUser;

  const isAccessToOperatorAllowed = isAdminUser || isProducerUser || isModeratorUser;
  const isAccessToOperatorMessagingAllowed = isAdminUser || isModeratorUser;
  const isFullAccessToOperatorAllowed = isAdminUser || isProducerUser;
  const isModeratorAccessingMessagingOperator = isModeratorUser && pathname.includes(routes.operator_messaging);

  const hasNoPermissions = !isAdminUser && !isEditorUser && !isProducerUser && !isModeratorUser;

  return {
    hasNoPermissions,
    isAccessToChannelSettingsAllowed,
    isAccessToEventSetupAllowed,
    isAccessToOperatorAllowed,
    isAccessToOperatorMessagingAllowed,
    isAccessToUserPageAllowed,
    isEditorAndModerator,
    isEventCreationAllowed,
    isEventDeletionAllowed,
    isEventDuplicationAllowed,
    isEventEditingAllowed,
    isEventReportingAllowed,
    isFullAccessToOperatorAllowed,
    isModeratorAccessingMessagingOperator,
  };
}
