import { useTranslation } from 'react-i18next';

import { WebcastSetupPage } from './webcast-setup';
import accessDeniedOperatorImage from '../../../assets/access-denied-operator.svg';
import { ErrorPage } from '../../../components/error-page/error-page';
import { useUserPermissions } from '../../hooks/user-permissions';

export function WebcastGuard() {
  const { t } = useTranslation();
  const { isAccessToEventSetupAllowed } = useUserPermissions();

  if (!isAccessToEventSetupAllowed) {
    return (
      <ErrorPage
        data-testid="access-denied"
        title={t('common.accessDenied.title')}
        subTitle={t('common.accessDenied.subTitle')}
        image={accessDeniedOperatorImage}
        style={{ gridArea: 'section', height: 'unset', background: 'none' }}
      />
    );
  }

  return <WebcastSetupPage />;
}
