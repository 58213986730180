import { Paragraph } from '@movingimage-evp/mi-ui-component-library';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

import { EventSummaryHeader } from './components/event-summary-header/event-summary-header';
import { EventSummaryMetrics } from './components/event-summary-metrics/event-summary-metrics';
import { EventSummaryRecording } from './components/event-summary-recording/event-summary-recording';
import { EventSummaryUpload } from './components/event-summary-upload/event-summary-upload';
import { EventSummaryVod } from './components/event-summary-vod/event-summary-vod';
import { useGetWebcastSummaryManagerQuery } from '../../../../generated/graphql-manager';
import { getLanguageKey } from '../../../../utils';
import { useGetPrimaryTitle } from '../../../hooks/use-get-primary-title';

import styles from './event-summary.module.css';

export function EventSummary() {
  const { t } = useTranslation();
  const { webcastId = '' } = useParams();
  const title = useGetPrimaryTitle() || '';

  const { data, refetch } = useGetWebcastSummaryManagerQuery({
    variables: { webcastId },
    skip: !webcastId,
  });

  const webcast = data?.webcast;
  const session = data?.session;
  const primaryLanguage = webcast?.primaryLanguage || '';
  const additionalLanguages = webcast?.additionalLanguages || [];
  const languages = [primaryLanguage, ...additionalLanguages];

  if (!webcast) return null;

  return (
    <>
      <section className={styles.container} data-testid="event-summary-page">
        <EventSummaryHeader webcast={webcast} webcastTitle={title} session={session} />

        <EventSummaryMetrics webcast={webcast} session={session} />

        <div className={styles.videoWrapper} data-testid="event-summary-video">
          <EventSummaryVod webcast={webcast} onVideoSelect={refetch} />

          {languages.length > 1 && (
            <div className={styles.languagesContainer}>
              <Paragraph data-testid="languages-header">{t('manager.eventSummary.languages')}</Paragraph>

              {languages.filter(Boolean).map((language) => (
                <div className={styles.languageName} key={language}>
                  <Paragraph data-testid={`event-summary-language-${language}`}>
                    {t(`common.languages.${getLanguageKey(language)}`)}
                  </Paragraph>
                </div>
              ))}
            </div>
          )}

          <EventSummaryRecording webcast={webcast} />

          <EventSummaryUpload webcast={webcast} isMultilanguages={Boolean(languages?.length > 1)} />
        </div>
      </section>
    </>
  );
}
