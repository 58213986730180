import { CheckIcon, SubHeading, Tab, Tabs } from '@movingimage-evp/mi-ui-component-library';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

import { PresentationUploader } from './presentation-uploader';
import { useGetWebcastPresentationsQuery } from '../../../../../generated/graphql-manager';
import { getLanguageKey } from '../../../../../utils';
import { getPresentationStatus } from '../../../../providers/presentations-processor';
import { MediaAccordion } from '../media-accordion';
import styles from '../media.module.css';

type Props = {
  onLoadStart?: () => void;
  onLoadEnd?: () => void;
};

export function Presentations({ onLoadStart, onLoadEnd }: Props) {
  const { webcastId = '' } = useParams();
  const { t } = useTranslation();

  const { data } = useGetWebcastPresentationsQuery({ variables: { webcastId } });

  if (!data?.webcast) return null;

  const { presentations, primaryLanguage, additionalLanguages } = data.webcast;
  const languages = [primaryLanguage, ...additionalLanguages];

  return (
    <div className={styles.wrapper}>
      <SubHeading>{t('manager.webcastSetup.media.presentationsHeading')}</SubHeading>

      {languages.length === 1 && (
        <PresentationUploader language={primaryLanguage} onLoadStart={onLoadStart} onLoadEnd={onLoadEnd} />
      )}

      {languages.length > 1 && (
        <Tabs compact={languages.length <= 4}>
          {languages.map((language) => {
            const translatedLanguage = t(
              `manager.webcastSetup.language.supportedLanguages.${getLanguageKey(language)}`
            );

            const label =
              language === primaryLanguage
                ? t('manager.webcastSetup.media.primaryLanguage', { primaryLanguage: translatedLanguage })
                : translatedLanguage;

            const presentation = presentations.find((presentation) => presentation.language === language);

            const { processingFinished } = getPresentationStatus(presentation);

            return (
              <Tab
                key={language}
                data-testid={`presentation-tab-${language}`}
                label={
                  <>
                    {label}
                    {processingFinished && <CheckIcon className={styles.presentationExistsIcon} />}
                  </>
                }
              >
                <MediaAccordion testId={`presentation-${language}`} visible={!presentation}>
                  <PresentationUploader language={language} onLoadStart={onLoadStart} onLoadEnd={onLoadEnd} />
                </MediaAccordion>
              </Tab>
            );
          })}
        </Tabs>
      )}
    </div>
  );
}
