import type { ReactNode } from 'react';

import { Note, usePrevious } from '@movingimage-evp/mi-ui-component-library';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';

import styles from './error-component.module.css';

export type GraphqlError = {
  message: string;
};

type Props = {
  error?: GraphqlError;
  title?: string;
  children?: ReactNode;
  onClose: () => void;
};

export function ErrorComponent({ error, title, children, onClose }: Props) {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const previousPathname = usePrevious(pathname);
  const errorTitle = title || t('common.graphQlErrorTitle');

  useEffect(() => {
    if (pathname !== previousPathname) onClose();
  }, [pathname, previousPathname, onClose]);

  return (
    <>
      {error && (
        <Note
          testId="error-notification"
          title={errorTitle}
          variant="negative"
          className={styles.notification}
          onClose={onClose}
        >
          {error.message}
        </Note>
      )}

      {children}
    </>
  );
}
