import type {
  AiSubtitlesFeature,
  BrowserBasedStudioFeature,
  LiveStreamingHoursFeature,
} from '../../../generated/graphql-manager';

import { useParams } from 'react-router';

import { useGetFeaturesQuery } from '../../../generated/graphql-manager';

export function useGetFeatures() {
  const { channelId = '' } = useParams();
  const { data } = useGetFeaturesQuery({ variables: { lsproId: channelId }, skip: !channelId });

  const features = data?.lsproSettings?.features || [];

  const isBrowserBasedStudioFeatureEnabled = features.find(
    (feature): feature is BrowserBasedStudioFeature => feature.__typename === 'BrowserBasedStudioFeature'
  )?.enabled;

  const isLiveStreamingHoursFeatureEnabled = features.find(
    (feature): feature is LiveStreamingHoursFeature => feature.__typename === 'LiveStreamingHoursFeature'
  )?.enabled;

  const isAiSubtitleFeatureEnabled = features.find(
    (feature): feature is AiSubtitlesFeature => feature.__typename === 'AiSubtitlesFeature'
  )?.enabled;

  return { isBrowserBasedStudioFeatureEnabled, isLiveStreamingHoursFeatureEnabled, isAiSubtitleFeatureEnabled };
}
