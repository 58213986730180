import type { RestrictionType } from './whitelist-modal/sso-whitelist-type-modal';
import type { UpdateSingleSignOnPolicyInput } from '../../../../generated/graphql-manager';
import type { ChangeEvent } from 'react';

import { NetworkStatus } from '@apollo/client';
import {
  CopyToClipboard,
  Heading,
  InfoCircleIcon,
  InputDescription,
  Notification,
  Paragraph,
  PrimaryButton,
  Radio,
  SelectionInputLayout,
  Spinner,
  TextInput,
  Toggle,
  Tooltip,
  classNames,
} from '@movingimage-evp/mi-ui-component-library';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

import { validateAccessCode } from './validate-access-code';
import { Whitelist } from './whitelist';
import { SsoWhitelistTypeModal, WhitelistModal } from './whitelist-modal';
import {
  ItemListAction,
  State,
  useGetWebcastSecurityQuery,
  useUpdateAccessCodePolicyMutation,
  useUpdateEmailRegistrationPolicyMutation,
  useUpdateEmailWhitelistPolicyMutation,
  useUpdateSingleSignOnPolicyMutation,
} from '../../../../generated/graphql-manager';
import { useAbsoluteRoutes } from '../../../../routes';
import {
  findAccessCodePolicy,
  findEmailRegistrationPolicy,
  findEmailWhitelistPolicy,
  findSingleSignOnPolicy,
} from '../../../../utils/graphql-helpers';
import { SetupPageFooter } from '../../../components/setup-page-footer';
import { useCurrentUser } from '../../../hooks/current-user';
import { useUserPermissions } from '../../../hooks/user-permissions';
import managerStyles from '../../../manager.module.css';

import styles from './viewer-access.module.css';

export type SecuritySettingsData = {
  sharingUrl: string;
  securedLink: boolean;
  accessCode: string;
};

export function ViewerAccessPage() {
  const { t } = useTranslation();
  const { lsproId } = useCurrentUser();
  const { isEventEditingAllowed } = useUserPermissions();
  const { webcastId = '' } = useParams();
  const routes = useAbsoluteRoutes();

  const { data, networkStatus, refetch } = useGetWebcastSecurityQuery({
    variables: { lsproId, webcastId },
    skip: !lsproId || !webcastId,
    notifyOnNetworkStatusChange: true,
  });

  const state = data?.webcast.state;
  const idps = data?.identityProviders;
  const securityPolicies = data?.webcast.securityPolicies;

  const accessCodePolicy = findAccessCodePolicy(securityPolicies);
  const singleSignOnPolicy = findSingleSignOnPolicy(securityPolicies);
  const emailRegistrationPolicy = findEmailRegistrationPolicy(securityPolicies);
  const emailWhitelistPolicy = findEmailWhitelistPolicy(securityPolicies);

  const [ssoWhitelistTypeModalOpen, setSsoWhitelistTypeModalOpen] = useState(false);
  const [emailWhitelistModalOpen, setEmailWhitelistModalOpen] = useState(false);

  const [numberOfItemsAdded, setNumberOfItemsAdded] = useState<number>();

  const whitelistedEmails = emailWhitelistPolicy?.emails || [];
  const ssoWhitelistedEmails = singleSignOnPolicy?.restrictions?.emails || [];
  const ssoWhitelistedIds = singleSignOnPolicy?.restrictions?.employeeIds || [];
  const ssoWhitelistedGroups = singleSignOnPolicy?.restrictions?.groups || [];

  const [updateAccessCodePolicyMutation, { loading: updateAccessCodePolicyLoading }] =
    useUpdateAccessCodePolicyMutation();

  const [updateSingleSignOnPolicyMutation, { loading: updateSingleSignOnPolicyLoading }] =
    useUpdateSingleSignOnPolicyMutation();

  const [updateEmailRegistrationPolicyMutation, { loading: updateEmailRegistrationPolicyLoading }] =
    useUpdateEmailRegistrationPolicyMutation();

  const [updateEmailWhitelistPolicyMutation, { loading: updateEmailWhitelistPolicyLoading }] =
    useUpdateEmailWhitelistPolicyMutation();

  const mutationInProgress = [
    updateAccessCodePolicyLoading,
    updateSingleSignOnPolicyLoading,
    updateEmailRegistrationPolicyLoading,
    updateEmailWhitelistPolicyLoading,
  ].some(Boolean);

  const disableOtherPolicies = async (
    newPolicy?: 'AccessCodePolicy' | 'SingleSignOnPolicy' | 'EmailRegistrationPolicy' | 'EmailWhitelistPolicy'
  ) => {
    if (newPolicy !== 'AccessCodePolicy' && accessCodePolicy?.enabled) {
      await updateAccessCodePolicy({ enabled: false });
    }

    if (newPolicy !== 'EmailRegistrationPolicy' && emailRegistrationPolicy?.enabled) {
      await updateEmailRegistrationPolicy({ enabled: false, requireNameFields: false });
    }

    if (newPolicy !== 'SingleSignOnPolicy' && singleSignOnPolicy?.enabled) {
      await updateSingleSignOnPolicy({ webcastId, enabled: false });
    }

    if (newPolicy !== 'EmailWhitelistPolicy' && emailWhitelistPolicy?.enabled) {
      await updateEmailWhitelistPolicy({ enabled: false, requireNameFields: false });
    }
  };

  const updateAccessCodePolicy = async ({
    enabled = Boolean(accessCodePolicy?.enabled),
    accessCode = accessCodePolicy?.accessCode || '',
  }: { enabled?: boolean; accessCode?: string } = {}) => {
    if (mutationInProgress) return;

    if (enabled) await disableOtherPolicies('AccessCodePolicy');

    return updateAccessCodePolicyMutation({
      variables: {
        input: {
          webcastId,
          enabled,
          accessCode,
        },
      },
      update: (_, { data }) => {
        if (data?.updateAccessCodePolicy.__typename === 'ValidationErrors') {
          setCustomAccessCodeValidationError(data.updateAccessCodePolicy?.errors?.[0]?.message);
        }

        if (data?.updateAccessCodePolicy.__typename === 'UpdateAccessCodePolicySuccess') {
          setCustomAccessCodeValidationError(undefined);
        }
      },
      onCompleted: () => refetch(),
    });
  };

  const updateSingleSignOnPolicy = async (input: UpdateSingleSignOnPolicyInput) => {
    if (mutationInProgress) return;

    if (input.enabled) await disableOtherPolicies('SingleSignOnPolicy');

    return updateSingleSignOnPolicyMutation({
      variables: {
        input: {
          ...input,
          webcastId,
          idps: idps?.map(({ alias }) => alias),
        },
      },
      onCompleted: () => refetch(),
    });
  };

  const updateEmailRegistrationPolicy = async ({
    enabled = Boolean(emailRegistrationPolicy?.enabled),
    requireNameFields = Boolean(emailRegistrationPolicy?.requireNameFields),
  }: { enabled?: boolean; requireNameFields?: boolean } = {}) => {
    if (mutationInProgress) return;

    if (enabled) await disableOtherPolicies('EmailRegistrationPolicy');

    return updateEmailRegistrationPolicyMutation({
      variables: {
        input: {
          webcastId,
          enabled,
          requireNameFields: enabled ? requireNameFields : false,
        },
      },
      onCompleted: () => refetch(),
    });
  };

  const updateEmailWhitelistPolicy = async ({
    enabled,
    requireNameFields,
  }: { enabled?: boolean; requireNameFields?: boolean } = {}) => {
    if (mutationInProgress) return;

    if (enabled) await disableOtherPolicies('EmailWhitelistPolicy');

    return updateEmailWhitelistPolicyMutation({
      variables: {
        input: {
          webcastId,
          enabled: enabled ?? null,
          emails: null,
          requireNameFields: requireNameFields ?? null,
        },
      },
      onCompleted: () => refetch(),
    });
  };

  const addWhitelistEmails = (items: string[]) => {
    if (mutationInProgress) return;

    return updateEmailWhitelistPolicyMutation({
      variables: {
        input: {
          webcastId,
          enabled: null,
          emails: [
            {
              action: ItemListAction.ADD,
              items: items.map((item) => item.toLowerCase()),
            },
          ],
          requireNameFields: null,
        },
      },
      onCompleted: () => {
        setNumberOfItemsAdded(items.length);
        refetch();
      },
    });
  };

  const removeWhitelistEmails = (items: string[]) => {
    if (mutationInProgress) return;

    return updateEmailWhitelistPolicyMutation({
      variables: {
        input: {
          webcastId,
          enabled: null,
          emails: [
            {
              action: ItemListAction.REMOVE,
              items,
            },
          ],
          requireNameFields: null,
        },
      },
      onCompleted: () => refetch(),
    });
  };

  const addSsoItems = (type: RestrictionType, items: string[]) => {
    if (mutationInProgress) return;

    setSsoWhitelistTypeModalOpen(false);

    return updateSingleSignOnPolicyMutation({
      variables: {
        input: {
          webcastId,
          [type]: [
            {
              action: ItemListAction.ADD,
              items: type === 'emails' ? items.map((item) => item.toLowerCase()) : items,
            },
          ],
        },
      },
      onCompleted: () => {
        setNumberOfItemsAdded(items.length);
        refetch();
      },
    });
  };

  const removeSsoItems = (type: RestrictionType, items: string[]) => {
    if (mutationInProgress) return;

    return updateSingleSignOnPolicyMutation({
      variables: {
        input: {
          webcastId,
          [type]: [
            {
              action: ItemListAction.REMOVE,
              items,
            },
          ],
        },
      },
      onCompleted: () => refetch(),
    });
  };

  const [customAccessCode, setCustomAccessCode] = useState(accessCodePolicy?.accessCode || '');
  const [customAccessCodeValidationError, setCustomAccessCodeValidationError] = useState<string>();

  useEffect(() => {
    if (accessCodePolicy?.enabled) setCustomAccessCode(accessCodePolicy?.accessCode || '');
  }, [accessCodePolicy?.enabled, accessCodePolicy?.accessCode]);

  const handleAccessCodeChange = (event: ChangeEvent<HTMLInputElement>) => {
    setCustomAccessCodeValidationError(undefined);
    setCustomAccessCode(event.target.value.trim());
  };

  const saveCustomAccessCode = () => {
    const error = validateAccessCode(customAccessCode);

    if (error) return setCustomAccessCodeValidationError(error);

    updateAccessCodePolicy({ accessCode: customAccessCode });
  };

  const loading = networkStatus === NetworkStatus.loading;
  const refetching = networkStatus === NetworkStatus.refetch;
  const showActiveSaveButton = accessCodePolicy?.enabled && customAccessCode !== accessCodePolicy?.accessCode;
  const formDisabled = !isEventEditingAllowed || mutationInProgress || refetching;
  const stateDisabled = state !== State.PRELIVE;
  const publicEventChecked = [accessCodePolicy, singleSignOnPolicy, emailWhitelistPolicy].every(
    (policy) => !policy?.enabled
  );

  return (
    <main className={classNames(managerStyles.main, styles.wrapper)} data-testid="viewer-access-page">
      <Heading className={managerStyles.grayText}>{t('manager.webcastSetup.viewerAccess.title')}</Heading>
      <Paragraph className={managerStyles.grayText}>{t('manager.webcastSetup.viewerAccess.heading')}</Paragraph>

      {loading && <Spinner style={{ margin: 'auto' }} />}

      {!loading && (
        <div className={styles.optionsWrapper}>
          <fieldset className={styles.options} disabled={formDisabled || (stateDisabled && !publicEventChecked)}>
            <SelectionInputLayout
              hintMessage={t('manager.webcastSetup.viewerAccess.options.off.description')}
              checked={publicEventChecked}
              inputElement={
                <Radio
                  data-testid="no-security-policy-radio"
                  name="viewer-access-type"
                  checked={publicEventChecked}
                  onChange={() => disableOtherPolicies()}
                >
                  {t('manager.webcastSetup.viewerAccess.options.off.label')}
                </Radio>
              }
            >
              {publicEventChecked && (
                <div
                  className={classNames(
                    styles.expandableSection,
                    formDisabled && !publicEventChecked && styles.disabled
                  )}
                >
                  <div className={styles.expandableSectionLabel}>
                    {t('manager.webcastSetup.viewerAccess.options.off.inputLabel')}

                    <Tooltip label={t('manager.webcastSetup.viewerAccess.options.off.hint')} position="above" delay={0}>
                      <InfoCircleIcon />
                    </Tooltip>
                  </div>

                  <div className={styles.expandableSectionOption}>
                    <span>{t('manager.webcastSetup.viewerAccess.options.off.fields.email')}</span>

                    <Toggle
                      data-testid="no-security-policy-require-email-toggle"
                      checked={emailRegistrationPolicy?.enabled === true}
                      disabled={stateDisabled}
                      onChange={() => updateEmailRegistrationPolicy({ enabled: !emailRegistrationPolicy?.enabled })}
                    />
                  </div>

                  <Tooltip label={t('manager.webcastSetup.viewerAccess.options.off.disabledTooltip')}>
                    <div className={styles.expandableSectionOption}>
                      <span className={classNames(emailRegistrationPolicy?.enabled === false && styles.disabled)}>
                        {t('manager.webcastSetup.viewerAccess.options.off.fields.name')}
                      </span>

                      <Toggle
                        data-testid="no-security-policy-require-name-fields-toggle"
                        checked={emailRegistrationPolicy?.requireNameFields === true}
                        disabled={!emailRegistrationPolicy?.enabled || stateDisabled}
                        onChange={() =>
                          updateEmailRegistrationPolicy({
                            requireNameFields: !emailRegistrationPolicy?.requireNameFields,
                          })
                        }
                      />
                    </div>
                  </Tooltip>
                </div>
              )}
            </SelectionInputLayout>
          </fieldset>

          <fieldset
            className={styles.options}
            disabled={formDisabled || (stateDisabled && accessCodePolicy?.enabled !== true)}
          >
            <SelectionInputLayout
              hintMessage={t('manager.webcastSetup.viewerAccess.options.accessCode.description')}
              checked={accessCodePolicy?.enabled === true}
              inputElement={
                <Radio
                  data-testid="access-code-radio"
                  name="viewer-access-type"
                  checked={accessCodePolicy?.enabled === true}
                  onChange={() => updateAccessCodePolicy({ enabled: true })}
                >
                  {t('manager.webcastSetup.viewerAccess.options.accessCode.label')}
                </Radio>
              }
            >
              {accessCodePolicy?.enabled && (
                <InputDescription
                  inputId="access-code-input"
                  label={t('manager.webcastSetup.viewerAccess.options.accessCode.inputLabel')}
                  helpMessage={t('manager.webcastSetup.viewerAccess.options.accessCode.hint')}
                  errorMessage={customAccessCodeValidationError}
                >
                  <TextInput
                    data-testid="access-code-input"
                    value={customAccessCode}
                    onChange={handleAccessCodeChange}
                    readOnly={stateDisabled}
                  >
                    <CopyToClipboard value={customAccessCode} notificationPosition="left" />
                  </TextInput>
                </InputDescription>
              )}
            </SelectionInputLayout>
          </fieldset>

          <fieldset
            className={styles.options}
            disabled={formDisabled || (stateDisabled && emailWhitelistPolicy?.enabled !== true)}
          >
            <SelectionInputLayout
              hintMessage={t('manager.webcastSetup.viewerAccess.options.emailWhitelist.description')}
              checked={emailWhitelistPolicy?.enabled === true}
              inputElement={
                <Radio
                  data-testid="email-whitelist-radio"
                  name="viewer-access-type"
                  checked={emailWhitelistPolicy?.enabled === true}
                  onChange={() => updateEmailWhitelistPolicy({ enabled: true })}
                >
                  {t('manager.webcastSetup.viewerAccess.options.emailWhitelist.label')}
                </Radio>
              }
            >
              {emailWhitelistPolicy?.enabled && (
                <div className={classNames(styles.expandableSection, formDisabled && styles.disabled)}>
                  <div className={styles.expandableSectionLabel}>
                    {t('manager.webcastSetup.viewerAccess.options.emailWhitelist.inputLabel')}

                    <Tooltip
                      label={t('manager.webcastSetup.viewerAccess.options.emailWhitelist.hint')}
                      position="above"
                      delay={0}
                    >
                      <InfoCircleIcon />
                    </Tooltip>
                  </div>

                  <div className={styles.expandableSectionOption}>
                    <span>{t('manager.webcastSetup.viewerAccess.options.emailWhitelist.fields.name')}</span>

                    <Toggle
                      data-testid="email-whitelist-require-name-fields-toggle"
                      checked={emailWhitelistPolicy?.requireNameFields === true}
                      onChange={() =>
                        updateEmailWhitelistPolicy({ requireNameFields: !emailWhitelistPolicy?.requireNameFields })
                      }
                    />
                  </div>

                  <div className={styles.expandableSectionLabel}>
                    {t('manager.webcastSetup.viewerAccess.options.emailWhitelist.attendeeList.description')}
                  </div>

                  <div className={styles.expandableSectionOption}>
                    {whitelistedEmails.length === 0 && (
                      <PrimaryButton
                        data-testid="add-attendees-button"
                        type="button"
                        small
                        onClick={() => setEmailWhitelistModalOpen(true)}
                      >
                        {t('manager.webcastSetup.viewerAccess.options.emailWhitelist.attendeeList.addAttendees')}
                      </PrimaryButton>
                    )}

                    <Whitelist
                      type="emails"
                      addMoreButtonVisible
                      items={whitelistedEmails}
                      onAdd={() => setEmailWhitelistModalOpen(true)}
                      onRemove={(item) => removeWhitelistEmails([item])}
                      onRemoveAll={() => removeWhitelistEmails(whitelistedEmails)}
                    />
                  </div>
                </div>
              )}
            </SelectionInputLayout>
          </fieldset>

          {idps && idps.length > 0 && (
            <fieldset
              className={styles.options}
              disabled={formDisabled || (stateDisabled && singleSignOnPolicy?.enabled !== true)}
            >
              <SelectionInputLayout
                hintMessage={t('manager.webcastSetup.viewerAccess.options.sso.description')}
                checked={singleSignOnPolicy?.enabled === true}
                inputElement={
                  <Radio
                    data-testid="single-sign-on-radio"
                    name="viewer-access-type"
                    checked={singleSignOnPolicy?.enabled === true}
                    onChange={() => updateSingleSignOnPolicy({ webcastId, enabled: !singleSignOnPolicy?.enabled })}
                  >
                    {t('manager.webcastSetup.viewerAccess.options.sso.label')}
                  </Radio>
                }
              >
                {singleSignOnPolicy?.enabled && (
                  <div className={classNames(styles.expandableSection, formDisabled && styles.disabled)}>
                    <div className={styles.expandableSectionOption}>
                      <span>{t('manager.webcastSetup.viewerAccess.options.sso.restrictions')}</span>

                      <Toggle
                        data-testid="single-sign-on-restrictions-toggle"
                        checked={singleSignOnPolicy.restrictions?.enabled === true}
                        onChange={() =>
                          updateSingleSignOnPolicy({
                            webcastId,
                            restrictionsEnabled: !singleSignOnPolicy.restrictions?.enabled,
                          })
                        }
                      />
                    </div>

                    {singleSignOnPolicy.restrictions?.enabled && (
                      <div className={styles.expandableSectionOption}>
                        <PrimaryButton
                          data-testid="add-sso-whitelist-button"
                          type="button"
                          small
                          onClick={() => setSsoWhitelistTypeModalOpen(true)}
                        >
                          {t('manager.webcastSetup.viewerAccess.options.sso.addAttendees')}
                        </PrimaryButton>
                      </div>
                    )}

                    {singleSignOnPolicy.restrictions?.enabled && (
                      <div className={classNames(styles.expandableSectionOption, styles.sso)}>
                        <Whitelist
                          type="emails"
                          items={ssoWhitelistedEmails}
                          onAdd={() => setSsoWhitelistTypeModalOpen(true)}
                          onRemove={(item) => removeSsoItems('emails', [item])}
                          onRemoveAll={() => removeSsoItems('emails', ssoWhitelistedEmails)}
                        />

                        <Whitelist
                          type="employeeIds"
                          items={ssoWhitelistedIds}
                          onAdd={() => setSsoWhitelistTypeModalOpen(true)}
                          onRemove={(item) => removeSsoItems('employeeIds', [item])}
                          onRemoveAll={() => removeSsoItems('employeeIds', ssoWhitelistedIds)}
                        />

                        <Whitelist
                          type="groups"
                          items={ssoWhitelistedGroups}
                          onAdd={() => setSsoWhitelistTypeModalOpen(true)}
                          onRemove={(item) => removeSsoItems('groups', [item])}
                          onRemoveAll={() => removeSsoItems('groups', ssoWhitelistedGroups)}
                        />
                      </div>
                    )}
                  </div>
                )}
              </SelectionInputLayout>
            </fieldset>
          )}
        </div>
      )}

      <SetupPageFooter
        nextLabel={t('manager.webcastSetup.viewerAccess.nextStep.label')}
        route={routes.webcastSetup_embedding}
        saving={mutationInProgress}
        showActiveSaveButton={showActiveSaveButton}
        saveButtonDisabled={customAccessCodeValidationError !== undefined}
        onSaveButtonClick={saveCustomAccessCode}
      />

      <WhitelistModal
        type="emails"
        isOpen={emailWhitelistModalOpen}
        onConfirm={addWhitelistEmails}
        onClose={() => setEmailWhitelistModalOpen(false)}
      />

      <SsoWhitelistTypeModal
        isOpen={ssoWhitelistTypeModalOpen}
        onConfirm={addSsoItems}
        onClose={() => setSsoWhitelistTypeModalOpen(false)}
      />

      {numberOfItemsAdded && (
        <Notification
          data-testid="emails-added-notification"
          status="success"
          text={t('manager.webcastSetup.viewerAccess.options.emailWhitelist.numberOfEmailsAdded', {
            count: numberOfItemsAdded,
          })}
          className={styles.emailsAddedNotification}
          onAnimationEnd={() => setNumberOfItemsAdded(undefined)}
        />
      )}
    </main>
  );
}
