import type { ReportType } from '../../../../../../generated/graphql-manager';
import type { AnchorHTMLAttributes, ButtonHTMLAttributes } from 'react';

import { DownloadIcon, SecondaryButton, Spinner, Tooltip } from '@movingimage-evp/mi-ui-component-library';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router';

import {
  ReportStatus,
  useGenerateReportMutation,
  useGetWebcastReportQuery,
} from '../../../../../../generated/graphql-manager';
import { useUserPermissions } from '../../../../../hooks/user-permissions';

import styles from './download-report.module.css';

export type DownloadReportProps = {
  webcastId: string;
  label: string;
  generateLabel: string;
  reportType: ReportType;
} & ButtonHTMLAttributes<HTMLButtonElement> &
  AnchorHTMLAttributes<HTMLAnchorElement>;

export function DownloadReport({ webcastId, label, generateLabel, reportType, ...props }: DownloadReportProps) {
  const { t } = useTranslation();
  const { isEventReportingAllowed } = useUserPermissions();

  const { data, loading, refetch } = useGetWebcastReportQuery({
    variables: { webcastId, reportType },
    skip: !webcastId || !reportType,
  });

  const report = data?.report;
  const downloadLink = report?.downloadLink || undefined;
  const isReportPending = report?.status === ReportStatus.SCHEDULED || report?.status === ReportStatus.CREATED;
  const isReportStatusNoData = report?.status === ReportStatus.NO_DATA;

  const [generateReportMutation, { loading: generating }] = useGenerateReportMutation();

  const generateReport = () => {
    if (downloadLink) return;

    generateReportMutation({
      variables: {
        input: {
          webcastId,
          type: reportType,
        },
      },
      onCompleted: (data) => {
        if (data?.generateReport?.__typename === 'GenerateReportSuccess') {
          refetch();
        }
      },
    });
  };

  useEffect(() => {
    if (isReportPending) {
      const interval = setInterval(() => {
        refetch();
      }, 10000);

      return () => clearInterval(interval);
    }
  }, [isReportPending, refetch]);

  const getReportStatusMessage = (statusReport?: ReportStatus) => {
    switch (statusReport) {
      case undefined:
      case ReportStatus.EXPIRED:
      case ReportStatus.FAILED:
        return t('manager.eventSummary.reports.tooltip.generateReport');
      case ReportStatus.CREATED:
      case ReportStatus.SCHEDULED:
        return t('manager.eventSummary.reports.tooltip.scheduledReport');
      case ReportStatus.NO_DATA:
        return t('manager.eventSummary.reports.tooltip.noData');
      case ReportStatus.READY:
      default:
        return undefined;
    }
  };

  const tooltipLabel = isEventReportingAllowed
    ? getReportStatusMessage(report?.status)
    : t('manager.webcastSetup.notEditableMessage.role');

  const isDownloadButtonDisable =
    loading || generating || !isEventReportingAllowed || isReportStatusNoData || isReportPending;

  return (
    <Tooltip label={tooltipLabel} hidden={Boolean(isEventReportingAllowed && report?.status === 'READY')}>
      <SecondaryButton
        small
        component={NavLink}
        to={downloadLink}
        target="_blank"
        className={styles.downloadButton}
        disabled={isDownloadButtonDisable}
        onClick={generateReport}
        data-testid="download-report-button"
        {...props}
      >
        {loading || generating || isReportPending ? (
          <Spinner size={18} style={{ margin: 'auto' }} />
        ) : downloadLink ? (
          <>
            <DownloadIcon /> {label}
          </>
        ) : (
          generateLabel
        )}
      </SecondaryButton>
    </Tooltip>
  );
}
