import type { SelectOption } from '@movingimage-evp/mi-ui-component-library';

import {
  FileInput,
  FileOutlineIcon,
  IconButton,
  InputDescription,
  Modal,
  PlusIcon,
  PrimaryButton,
  SecondaryButton,
  Select,
  TextInput,
  Tooltip,
  TrashIcon,
} from '@movingimage-evp/mi-ui-component-library';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useCurrentUser } from 'src/manager/hooks/current-user';
import { uploadFile } from 'src/utils';

import { FontListItem } from './font-list-item';
import {
  AssetUploadStatusCode,
  GetLsproBrandingSettingsDocument as GET_LSPRO_BRANDING_SETTINGS,
  useCreateLsproCustomFontMutation,
  useGetLsproBrandingSettingsQuery,
} from '../../../../generated/graphql-manager';

import styles from './font-and-typography.module.css';

type Props = {
  headingFont: string;
  textFont: string;
  onHeadingFontChange: (font: string) => void;
  onTextFontChange: (font: string) => void;
};

export function FontAndTypography({ headingFont, textFont, onHeadingFontChange, onTextFontChange }: Props) {
  const { t } = useTranslation();
  const { lsproId } = useCurrentUser();
  const xhrRef = useRef<XMLHttpRequest>();

  const [uploadFontModalVisible, setUploadFontModalVisible] = useState(false);
  const [customFontName, setCustomFontName] = useState('');
  const [fontFile, setFontFile] = useState<File | null>(null);

  const closeModal = () => {
    setUploadFontModalVisible(false);
    setCustomFontName('');
    setFontFile(null);
  };

  const { data, startPolling, stopPolling } = useGetLsproBrandingSettingsQuery({ variables: { lsproId } });

  const fonts = data?.lsproSettings?.appearance?.supportedFonts;
  const customFonts = fonts?.filter((font) => !font.isStandard);
  const fontOptions =
    fonts
      ?.filter(({ isStandard, asset }) => isStandard || asset?.status.code === AssetUploadStatusCode.UPLOADED)
      .map(({ id, name }) => ({ key: id, label: name || id })) || [];

  const chosenFontHeadingLabel = fonts?.find((font) => font.id === headingFont);
  const chosenFontTextLabel = fonts?.find((font) => font.id === textFont);

  const [createLsproCustomFontMutation] = useCreateLsproCustomFontMutation();
  const createCustomFont = () => {
    createLsproCustomFontMutation({
      variables: {
        input: {
          lsproId,
          name: customFontName,
        },
      },
      refetchQueries: [{ query: GET_LSPRO_BRANDING_SETTINGS, variables: { lsproId } }],
      onCompleted: (data) => {
        if (data.createFont.__typename !== 'CreateFontSuccess' || !fontFile) {
          console.log('Fail to upload');
          return;
        }

        const {
          uploadLink: { url, token },
        } = data.createFont;

        xhrRef.current = uploadFile({
          file: fontFile,
          token,
          url,
        });

        closeModal();
      },
    });
  };

  useEffect(() => {
    if (fonts?.some((font) => font.asset?.status.code === AssetUploadStatusCode.PENDING)) {
      startPolling(5000);
    }

    return stopPolling;
  }, [fonts, startPolling, stopPolling]);

  if (!fontOptions.length) return null;

  return (
    <>
      <div data-testid="font-and-typography" className={styles.wrapper}>
        <InputDescription label={t('manager.branding.fontAndTypography.headingFont')}>
          <Select
            data-testid="heading-font-select"
            placeholder={t('common.select')}
            optionsIdProperty="key"
            value={{ key: chosenFontHeadingLabel?.name || chosenFontHeadingLabel?.id }}
            options={fontOptions}
            onChange={(option: SelectOption) => onHeadingFontChange(option.key)}
          />
        </InputDescription>

        <InputDescription label={t('manager.branding.fontAndTypography.textFont')}>
          <Select
            data-testid="text-font-select"
            placeholder={t('common.select')}
            optionsIdProperty="key"
            value={{ key: chosenFontTextLabel?.name || chosenFontTextLabel?.id }}
            options={fontOptions}
            onChange={(option: SelectOption) => onTextFontChange(option.key)}
          />
        </InputDescription>

        <PrimaryButton onClick={() => setUploadFontModalVisible(true)} data-testid="upload-custom-font-button">
          {t('manager.branding.fontAndTypography.uploadCustomFontButton')}
          <PlusIcon />
        </PrimaryButton>

        {customFonts && customFonts?.length > 0 && <p>{t('manager.branding.fontAndTypography.uploadedFonts')}</p>}

        {customFonts?.map((font, index) => (
          <FontListItem key={font.id} font={font} headingFont={headingFont} textFont={textFont} testId={index} />
        ))}
      </div>

      <Modal
        data-testid="upload-font-modal"
        title={t('manager.branding.fontAndTypography.modal.title')}
        isOpen={uploadFontModalVisible}
        onClose={closeModal}
        contentProps={{ className: styles.uploadFontModalContent }}
        footer={
          <>
            <SecondaryButton data-testid="cancel-upload-font-button" onClick={closeModal}>
              {t('manager.branding.fontAndTypography.modal.cancelButton')}
            </SecondaryButton>

            <PrimaryButton
              data-testid="save-custom-font-button"
              disabled={!customFontName || !fontFile}
              onClick={createCustomFont}
            >
              {t('manager.branding.fontAndTypography.modal.saveFontButton')}
            </PrimaryButton>
          </>
        }
      >
        <InputDescription
          label={t('manager.branding.fontAndTypography.modal.fontName.label')}
          helpMessage={t('manager.branding.fontAndTypography.modal.fontName.helpMessage')}
        >
          <TextInput
            data-testid="custom-font-name-input"
            placeholder={t('manager.branding.fontAndTypography.modal.fontName.placeholder')}
            value={customFontName}
            onChange={(event) => setCustomFontName(event.target.value)}
          />
        </InputDescription>

        {fontFile ? (
          <div className={styles.filePreview} data-testid="font-file-preview">
            <p>{fontFile.name}</p>

            <Tooltip label={t('manager.branding.fontAndTypography.modal.fileUpload.deleteButton')}>
              <IconButton
                data-testid="delete-font-file-button"
                rounded
                aria-label={t('manager.branding.fontAndTypography.modal.fileUpload.deleteButton')}
                onClick={() => setFontFile(null)}
              >
                <TrashIcon />
              </IconButton>
            </Tooltip>
          </div>
        ) : (
          <FileInput
            data-testid="font-file-uploader"
            icon={<FileOutlineIcon />}
            acceptedFormats={t('manager.branding.fontAndTypography.modal.fileUpload.acceptedFormats')}
            info={t('manager.branding.fontAndTypography.modal.fileUpload.info')}
            additionalUploadInformation={['TTF, OTF']}
            onChange={(files) => setFontFile(files[0])}
          />
        )}
      </Modal>
    </>
  );
}
