import type { SelectOption } from '@movingimage-evp/mi-ui-component-library';
import type { ChangeEvent } from 'react';

import {
  CopyToClipboard,
  Heading,
  InputDescription,
  Note,
  Paragraph,
  Select,
  TextInput,
  Toggle,
  Tooltip,
  usePrevious,
} from '@movingimage-evp/mi-ui-component-library';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

import {
  IngestProtocol,
  State,
  useGetWebcastLanguagesQuery,
  useUpdateWebcastLanguagesMutation,
} from '../../../../generated/graphql-manager';
import { useAbsoluteRoutes } from '../../../../routes';
import { getAiTranslationKey, getLanguageKey } from '../../../../utils';
import { SetupPageFooter } from '../../../components/setup-page-footer';
import { useGetFeatures } from '../../../hooks/use-get-features';
import { useUserPermissions } from '../../../hooks/user-permissions';
import managerStyles from '../../../manager.module.css';

import styles from './language.module.css';

export function LanguagePage() {
  const { webcastId = '' } = useParams();
  const { t } = useTranslation();
  const routes = useAbsoluteRoutes();
  const { isEventEditingAllowed } = useUserPermissions();
  const { isAiSubtitleFeatureEnabled } = useGetFeatures();

  const { data } = useGetWebcastLanguagesQuery({
    variables: { webcastId },
    onCompleted({ webcast, configuration }) {
      setPrimaryLanguage(webcast.primaryLanguage);
      setAdditionalLanguages(webcast.additionalLanguages.filter((language) => language !== webcast.primaryLanguage));
      setAiLiveTranscriptGeneration(webcast.subtitleSettings?.aiLiveTranscriptGeneration === true);
      setTranslationLanguages(webcast.subtitleSettings?.translationLanguages || []);
      setLanguagesData(
        configuration.supportedLanguages.map((supportedLanguage) => ({
          language: supportedLanguage,
          title: webcast.contents.find(({ language }) => language === supportedLanguage)?.title || '',
        }))
      );
    },
  });

  const webcast = data?.webcast;
  const webcastPrimaryLanguage = webcast?.primaryLanguage;
  const webcastAdditionalLanguages = webcast?.additionalLanguages || [];
  const supportedLanguages = data?.configuration.supportedLanguages || [];
  const supportedSubtitleLanguages = data?.configuration.supportedSubtitleLanguages || [];
  const isSrtProtocol = webcast?.streaming?.live?.ingestProtocol === IngestProtocol.SRT;

  const [primaryLanguage, setPrimaryLanguage] = useState(webcastPrimaryLanguage || '');
  const previousPrimaryLanguage = usePrevious(primaryLanguage);

  const [additionalLanguages, setAdditionalLanguages] = useState(
    webcastAdditionalLanguages.filter((language) => language !== webcastPrimaryLanguage)
  );

  const [languagesData, setLanguagesData] = useState<{ language: string; title: string }[]>([]);

  const [aiLiveTranscriptGeneration, setAiLiveTranscriptGeneration] = useState(
    webcast?.subtitleSettings?.aiLiveTranscriptGeneration === true
  );

  const [translationLanguages, setTranslationLanguages] = useState(
    webcast?.subtitleSettings?.translationLanguages || []
  );

  const [updateWebcastLanguagesMutation, { loading }] = useUpdateWebcastLanguagesMutation();

  const onTitleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value, name } = event.target;

    setLanguagesData((state) =>
      state.map((language) => (language.language === name ? { ...language, title: value } : language))
    );
  };

  const saveChanges = () => {
    const languages = [
      { language: primaryLanguage, isPrimary: true },
      ...additionalLanguages.map((language) => ({ language, isPrimary: false })),
    ];

    const contents = languagesData.filter(
      (item) => additionalLanguages.includes(item.language) || item.language === primaryLanguage
    );

    updateWebcastLanguagesMutation({
      variables: {
        input: {
          id: webcastId,
          languages,
          subtitleSettings: { aiLiveTranscriptGeneration, translationLanguages },
          contents,
        },
      },
      optimisticResponse: {
        updateWebcast: {
          __typename: 'UpdateWebcastSuccess',
          webcast: {
            __typename: 'Webcast',
            id: webcastId,
            primaryLanguage,
            additionalLanguages,
            subtitleSettings: { aiLiveTranscriptGeneration, translationLanguages },
            contents: languagesData,
          },
        },
      },
      update: (cache, { data }) => {
        if (data?.updateWebcast.__typename === 'UpdateWebcastSuccess') {
          cache.modify({
            id: cache.identify({ __typename: 'Webcast', id: webcastId }),
            fields: {
              presentations: (presentation) => {
                if (languages.length === 1) return [];
                return [...(presentation || [])];
              },
            },
          });
        }
      },
    });
  };

  const primaryLanguageTitle = languagesData?.find((language) => language.language === primaryLanguage)?.title || '';
  const previousPrimaryLanguageTitle = usePrevious(primaryLanguageTitle);

  useEffect(() => {
    const newSelectedPrimaryLanguageHasNoTitle = previousPrimaryLanguageTitle && !primaryLanguageTitle;
    const primaryLanguageHasChanged = previousPrimaryLanguage !== '' && previousPrimaryLanguage !== primaryLanguage;

    if (newSelectedPrimaryLanguageHasNoTitle) {
      setLanguagesData((state) =>
        state.map((language) => ({
          ...language,
          title: language.language === primaryLanguage ? previousPrimaryLanguageTitle : language.title,
        }))
      );
    }

    if (primaryLanguageHasChanged) {
      const hasSwitchedLanguageBeenChecked = additionalLanguages.includes(primaryLanguage);

      setAdditionalLanguages((state) =>
        (hasSwitchedLanguageBeenChecked ? [...state, previousPrimaryLanguage] : state).filter(
          (language) => language !== primaryLanguage
        )
      );
    }
  }, [
    additionalLanguages,
    previousPrimaryLanguage,
    previousPrimaryLanguageTitle,
    primaryLanguage,
    primaryLanguageTitle,
  ]);

  const editingDisabled = !isEventEditingAllowed || isSrtProtocol || webcast?.state !== State.PRELIVE;
  const aiSubtitlesDisabled = editingDisabled || webcast?.subtitleSettings?.aiLiveTranscriptGeneration === undefined;

  const supportedSubtitleLanguagesWithoutEventLanguages = supportedSubtitleLanguages.filter(
    (subtitleLanguage) =>
      subtitleLanguage !== primaryLanguage &&
      !additionalLanguages.some((language) => language.startsWith(subtitleLanguage))
  );

  const primaryLanguageOptions = supportedLanguages.map((language) => ({
    key: language,
    label: t(`manager.webcastSetup.language.supportedLanguages.${getLanguageKey(language)}`),
  }));

  const additionalLanguagesOptions = supportedLanguages
    .filter((language) => language !== primaryLanguage)
    .map((language) => ({
      key: language,
      label: t(`manager.webcastSetup.language.supportedLanguages.${getLanguageKey(language)}`),
    }));

  const aiSubtitlesOptions = supportedSubtitleLanguagesWithoutEventLanguages
    .map((language) => ({
      key: language,
      label: t(`common.languages.${getAiTranslationKey(language)}`),
    }))
    .sort((a, b) => (a.label > b.label ? 1 : -1));

  if (!primaryLanguage) return null;

  return (
    <main className={managerStyles.main} data-testid="language-page">
      <Heading className={managerStyles.grayText}>{t('manager.webcastSetup.language.title')}</Heading>
      <Paragraph>{t('manager.webcastSetup.language.headings.primaryLanguage')}</Paragraph>

      <InputDescription label={t('manager.webcastSetup.language.inputs.primaryLanguage.label')}>
        <Select
          data-testid="primary-language-select"
          disabled={editingDisabled}
          optionsIdProperty="key"
          value={primaryLanguageOptions.find((option) => option.key === primaryLanguage)}
          options={primaryLanguageOptions}
          onChange={(option: SelectOption) => setPrimaryLanguage(option.key)}
        />
      </InputDescription>

      <InputDescription label={t('manager.webcastSetup.language.inputs.additionalLanguages.label')}>
        <Tooltip
          delay={0}
          hidden={!isSrtProtocol}
          label={t('manager.webcastSetup.language.inputs.additionalLanguages.disabledInfo.srt')}
        >
          <Select
            data-testid="additional-languages"
            disabled={editingDisabled}
            placeholder={t('manager.webcastSetup.language.inputs.additionalLanguages.placeholder')}
            optionsIdProperty="key"
            value={additionalLanguagesOptions.filter((option) => additionalLanguages.includes(option.key))}
            options={additionalLanguagesOptions}
            onChange={(options) => setAdditionalLanguages(options.map((option: SelectOption) => option.key))}
          />
        </Tooltip>
      </InputDescription>

      {additionalLanguages.length > 0 && (
        <div className={styles.additionalLanguagesTitles}>
          <Paragraph>{t('manager.webcastSetup.language.headings.additionalLanguages')}</Paragraph>

          <InputDescription
            inputId="primary-language-title"
            label={t('manager.webcastSetup.language.inputs.primaryLanguageTitle.label')}
          >
            <TextInput
              value={primaryLanguageTitle}
              name={primaryLanguage}
              onChange={onTitleChange}
              data-testid="primary-language-title"
            >
              <CopyToClipboard value={primaryLanguageTitle} notificationPosition="left" />
            </TextInput>
          </InputDescription>

          {languagesData
            .filter(({ language }) => language !== primaryLanguage && additionalLanguages.includes(language))
            .map(({ language, title }) => (
              <InputDescription
                key={language}
                inputId={`language-title-${language}`}
                label={t(`common.languages.${getLanguageKey(language)}`)}
              >
                <TextInput
                  data-testid={`language-title-${language}`}
                  name={language}
                  value={title || ''}
                  onChange={onTitleChange}
                />
              </InputDescription>
            ))}
        </div>
      )}

      {isAiSubtitleFeatureEnabled && (
        <div className={styles.aiSubtitles}>
          <Paragraph>{t('manager.webcastSetup.language.inputs.aiSubtitles.info')}</Paragraph>

          <Tooltip
            delay={0}
            hidden={!isSrtProtocol}
            label={t('manager.webcastSetup.language.inputs.aiSubtitles.disabledInfo.srt')}
          >
            <Toggle
              data-testid="ai-subtitles-toggle"
              checked={aiLiveTranscriptGeneration}
              disabled={aiSubtitlesDisabled}
              onChange={() => setAiLiveTranscriptGeneration((state) => !state)}
            >
              {t('manager.webcastSetup.language.inputs.aiSubtitles.label')}
            </Toggle>
          </Tooltip>

          {aiLiveTranscriptGeneration && (
            <InputDescription label={t('manager.webcastSetup.language.inputs.aiSubtitlesLanguages.label')}>
              <Select
                data-testid="ai-subtitles-languages"
                disabled={aiSubtitlesDisabled}
                placeholder={t('manager.webcastSetup.language.inputs.aiSubtitlesLanguages.placeholder')}
                optionsIdProperty="key"
                value={translationLanguages.map((language) => ({
                  key: language,
                  label: t(`common.languages.${getAiTranslationKey(language)}`),
                }))}
                options={aiSubtitlesOptions}
                onChange={(options) => setTranslationLanguages(options.map((option: SelectOption) => option.key))}
              />
            </InputDescription>
          )}

          {aiLiveTranscriptGeneration && (
            <Note variant="primary" className={styles.aiSubtitlesDelayInfo}>
              <strong>{t('common.aiSubtitlesDelayInfo.title')}</strong>
              {t('common.aiSubtitlesDelayInfo.full')}
            </Note>
          )}
        </div>
      )}

      <SetupPageFooter
        nextLabel={t('manager.webcastSetup.language.nextStep.label')}
        route={routes.webcastSetup_eventDescription}
        showActiveSaveButton
        saveButtonDisabled={editingDisabled}
        saving={loading}
        onSaveButtonClick={saveChanges}
      />
    </main>
  );
}
