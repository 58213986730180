import { useTranslation } from 'react-i18next';

import { Asset } from './asset';
import { AppearanceAssetType, useGetLsproBrandingSettingsQuery } from '../../../../generated/graphql-manager';
import { useCurrentUser } from '../../../hooks/current-user';

import styles from './logo-and-favicon.module.css';

const COPY = 'manager.branding.logoAndFavicon.fileUpload';
const { LOGO, HORIZONTAL_LOGO, FAVICON } = AppearanceAssetType;

export function LogoAndFavicon() {
  const { t } = useTranslation();
  const { lsproId } = useCurrentUser();
  const { data } = useGetLsproBrandingSettingsQuery({ variables: { lsproId } });

  const assets = data?.lsproSettings?.appearance.assets;

  return (
    <div className={styles.wrapper}>
      <Asset
        asset={assets?.find(({ type }) => type === LOGO)}
        assetType={LOGO}
        acceptedFormats=".jpg, .jpeg, .png, .svg, .gif"
        maximumFileSize={2097152} // 2MB
        heading={t(`${COPY}.${LOGO}.name`)}
        additionalUploadInformation={[t(`${COPY}.${LOGO}.restrictions`), t(`${COPY}.${LOGO}.recommendations`)]}
      />

      <Asset
        asset={assets?.find(({ type }) => type === HORIZONTAL_LOGO)}
        assetType={HORIZONTAL_LOGO}
        acceptedFormats=".jpg, .jpeg, .png, .svg, .gif"
        maximumFileSize={2097152} // 2MB
        heading={t(`${COPY}.${HORIZONTAL_LOGO}.name`)}
        additionalUploadInformation={[
          t(`${COPY}.${HORIZONTAL_LOGO}.restrictions`),
          t(`${COPY}.${HORIZONTAL_LOGO}.recommendations`),
        ]}
      />

      <Asset
        asset={assets?.find(({ type }) => type === FAVICON)}
        assetType={FAVICON}
        acceptedFormats=".ico, .svg"
        maximumFileSize={2097152} // 2MB
        heading={t(`${COPY}.${FAVICON}.name`)}
        additionalUploadInformation={[t(`${COPY}.${FAVICON}.restrictions`), t(`${COPY}.${FAVICON}.recommendations`)]}
      />
    </div>
  );
}
