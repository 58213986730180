import type { AppearanceColors } from '../../../../generated/graphql-manager';

import { InputDescription, PrimaryButton, SaveButton, SecondaryButton } from '@movingimage-evp/mi-ui-component-library';
import { useTranslation } from 'react-i18next';

import { ColorPicker } from './color-picker';
import { Theme } from '../../../../generated/graphql-manager';

import styles from './theme-and-color.module.css';

export type ColorKeys = Exclude<keyof AppearanceColors, '__typename' | 'theme'>;
export type ColorSet = { theme: Theme } & Record<ColorKeys, string>;

type Props = {
  theme: Theme;
  appearanceColors: ColorSet[];
  errors: ColorSet[];
  saving: boolean;
  savingDisabled: boolean;
  onColorChange: (name: ColorKeys, value: string) => void;
  onInputBlur: (name: ColorKeys) => void;
  onThemeChange: (theme: Theme) => void;
  onThemeAndColorSave: () => void;
};

export function ThemeAndColor({
  theme,
  appearanceColors,
  errors,
  saving,
  savingDisabled,
  onThemeChange,
  onColorChange,
  onInputBlur,
  onThemeAndColorSave,
}: Props) {
  const { t } = useTranslation();

  const DarkButton = theme === Theme.DARK ? PrimaryButton : SecondaryButton;
  const LightButton = theme === Theme.LIGHT ? PrimaryButton : SecondaryButton;

  const themeColors = appearanceColors.find((colorSet) => colorSet.theme === theme);
  const themeErrors = errors.find((colorSet) => colorSet.theme === theme);

  if (!themeColors || !themeErrors) return null;

  return (
    <div key={theme} className={styles.wrapper}>
      <div className={styles.buttons}>
        <DarkButton data-testid="theme-button-dark" small onClick={() => onThemeChange(Theme.DARK)}>
          {t(`manager.branding.themeAndColor.${Theme.DARK}`)}
        </DarkButton>

        <LightButton data-testid="theme-button-light" small onClick={() => onThemeChange(Theme.LIGHT)}>
          {t(`manager.branding.themeAndColor.${Theme.LIGHT}`)}
        </LightButton>
      </div>

      <h2>{t('manager.branding.themeAndColor.paragraph')}</h2>

      <InputDescription
        inputId="primaryColor"
        label={t('manager.branding.themeAndColor.primaryColor')}
        errorMessage={themeErrors.primaryColor}
      >
        <ColorPicker
          data-testid="primary-color-picker"
          id="primaryColor"
          value={themeColors.primaryColor}
          onInput={(event) => onColorChange('primaryColor', event.currentTarget.value)}
          onBlur={() => onInputBlur('primaryColor')}
        />
      </InputDescription>

      <InputDescription
        inputId="cardColor"
        label={t('manager.branding.themeAndColor.cardColor')}
        errorMessage={themeErrors.cardColor}
      >
        <ColorPicker
          data-testid="card-color-picker"
          id="cardColor"
          value={themeColors.cardColor}
          onInput={(event) => onColorChange('cardColor', event.currentTarget.value)}
          onBlur={() => onInputBlur('cardColor')}
        />
      </InputDescription>

      <InputDescription
        inputId="backgroundColor"
        label={t('manager.branding.themeAndColor.backgroundColor')}
        errorMessage={themeErrors.backgroundColor}
      >
        <ColorPicker
          data-testid="background-color-picker"
          id="backgroundColor"
          value={themeColors.backgroundColor}
          onInput={(event) => onColorChange('backgroundColor', event.currentTarget.value)}
          onBlur={() => onInputBlur('backgroundColor')}
        />
      </InputDescription>

      <InputDescription
        inputId="headingFontColor"
        label={t('manager.branding.themeAndColor.headingFontColor')}
        errorMessage={themeErrors.headingFontColor}
      >
        <ColorPicker
          data-testid="heading-font-color-picker"
          id="headingFontColor"
          value={themeColors.headingFontColor}
          onInput={(event) => onColorChange('headingFontColor', event.currentTarget.value)}
          onBlur={() => onInputBlur('headingFontColor')}
        />
      </InputDescription>

      <InputDescription
        inputId="textFontColor"
        label={t('manager.branding.themeAndColor.textFontColor')}
        errorMessage={themeErrors.textFontColor}
      >
        <ColorPicker
          data-testid="text-font-color-picker"
          id="textFontColor"
          value={themeColors.textFontColor}
          onInput={(event) => onColorChange('textFontColor', event.currentTarget.value)}
          onBlur={() => onInputBlur('textFontColor')}
        />
      </InputDescription>

      <SaveButton
        data-testid="theme-and-color-save-button"
        small
        saving={saving}
        disabled={savingDisabled}
        onClick={onThemeAndColorSave}
      />
    </div>
  );
}
