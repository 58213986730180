import type { SecurityPolicy } from '../../../../generated/graphql-manager';

import {
  Accordion,
  CopyToClipboard,
  InfoCircleIcon,
  InputDescription,
  TextArea,
  TextInput,
  Tooltip,
} from '@movingimage-evp/mi-ui-component-library';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

import { useGetWebcastSetupSummaryQuery } from '../../../../generated/graphql-manager';
import { getLanguageKey } from '../../../../utils';
import { generateEmbedCode } from '../../../../utils/generate-embed-code';
import { findReferrerProtectionPolicy } from '../../../../utils/graphql-helpers';
import { useGetPrimaryTitle } from '../../../hooks/use-get-primary-title';
import { RestrictEmbedding } from '../embedding/restrict-embedding';

import commonStyles from './setup-summary.module.css';

export function SharingInfo() {
  const { webcastId = '' } = useParams();
  const title = useGetPrimaryTitle();
  const { t } = useTranslation();

  const { data } = useGetWebcastSetupSummaryQuery({ variables: { webcastId }, skip: !webcastId });
  const { sharingLink, securityPolicies, additionalLanguages, primaryLanguage } = data?.webcast || {};
  const sharingUrl = sharingLink?.sharingUrl || '';

  const referrerProtectionPolicy = findReferrerProtectionPolicy(securityPolicies);
  const embedCode = generateEmbedCode({ url: sharingUrl, title: title || '' });
  const isMultiLanguages = Boolean(additionalLanguages?.length);
  const multipleLanguages = [primaryLanguage, ...(additionalLanguages || [])];

  const getSharingUrlWithLanguage = (language: string) => {
    return `${sharingUrl}/${language}`;
  };

  const getEmbededCodeWithLanguage = (language: string) => {
    return generateEmbedCode({ url: getSharingUrlWithLanguage(language), title: title || '' });
  };

  return (
    <Accordion
      data-testid="sharing-info-accordion"
      buttonContent={
        <div className={commonStyles.accordionLabel}>
          <span>{t('manager.webcastSetup.summary.sharing.title')}</span>

          {referrerProtectionPolicy?.enabled && (
            <span data-testid="embed-restrictions">{t('manager.webcastSetup.summary.sharing.embedRestrictions')}</span>
          )}
        </div>
      }
    >
      {!isMultiLanguages && (
        <>
          <InputDescription inputId="sharing-url" label={t('manager.webcastSetup.summary.sharing.sharingLinkLabel')}>
            <TextInput readOnly value={sharingUrl} data-testid="sharing-url">
              <CopyToClipboard value={sharingUrl} notificationPosition="left" />
            </TextInput>
          </InputDescription>

          <InputDescription inputId="embed-code" label={t('manager.webcastSetup.summary.sharing.embedCodeLabel')}>
            <TextArea readOnly value={embedCode} data-testid="embed-code" rows={3}>
              <CopyToClipboard value={embedCode} notificationPosition="left" />
            </TextArea>
          </InputDescription>
        </>
      )}

      {isMultiLanguages && (
        <>
          <div className={commonStyles.tooltipWrapper}>
            {t('manager.webcastSetup.summary.sharing.sharingLinkMultilanguage')}

            <Tooltip label={t('manager.webcastSetup.summary.sharing.sharingLinkTooltip')} position="above" delay={0}>
              <InfoCircleIcon />
            </Tooltip>
          </div>

          {multipleLanguages?.map((language) => {
            if (!language) return null;

            return (
              <InputDescription
                key={language}
                inputId={`sharing-url-${language}`}
                label={t(`common.languages.${getLanguageKey(language)}`)}
              >
                <TextInput readOnly value={getSharingUrlWithLanguage(language)} data-testid={`sharing-url-${language}`}>
                  <CopyToClipboard value={getSharingUrlWithLanguage(language)} notificationPosition="left" />
                </TextInput>
              </InputDescription>
            );
          })}

          <div className={commonStyles.tooltipWrapper}>
            {t('manager.webcastSetup.summary.sharing.embedCodeLabel')}

            <Tooltip label={t('manager.webcastSetup.summary.sharing.embedCodeTooltip')} position="above" delay={0}>
              <InfoCircleIcon />
            </Tooltip>
          </div>

          {multipleLanguages?.map((language) => {
            if (!language) return null;

            return (
              <InputDescription
                key={language}
                inputId={`embed-code-${language}`}
                label={t(`common.languages.${getLanguageKey(language)}`)}
              >
                <TextArea
                  readOnly
                  value={getEmbededCodeWithLanguage(language)}
                  data-testid={`embed-code-${language}`}
                  rows={3}
                >
                  <CopyToClipboard value={getEmbededCodeWithLanguage(language)} notificationPosition="left" />
                </TextArea>
              </InputDescription>
            );
          })}
        </>
      )}

      {referrerProtectionPolicy?.enabled && (
        <InputDescription inputId="embed-restrictions" label={t('manager.webcastSetup.summary.sharing.allowedDomains')}>
          <RestrictEmbedding readOnly webcastId={webcastId} securityPolicies={securityPolicies as SecurityPolicy[]} />
        </InputDescription>
      )}
    </Accordion>
  );
}
