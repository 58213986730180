import type { WebcastWithTitle } from './types';
import type { State, GetWebcasts_WebcastFragment as Webcast } from '../../../generated/graphql-manager';
import type { EventsView } from '../../providers/ui-state-provider/ui-state-provider';
import type { ChangeEvent } from 'react';

import { NetworkStatus } from '@apollo/client';
import {
  GridIcon,
  Paragraph,
  PlusIcon,
  PrimaryButton,
  SecondaryButton,
  Spinner,
  Tooltip,
  UnorderedListIcon,
  classNames,
  usePrevious,
} from '@movingimage-evp/mi-ui-component-library';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router';

import { DeleteWebcastModal } from './delete-webcast/delete-webcast-modal';
import { DuplicateWebcastModal } from './duplicate-webcast/duplicate-webcast-modal';
import { DuplicationProgressIndicator } from './duplicate-webcast/duplication-progress-indicator';
import { GridView, ListView } from './views';
import { StateFilter, useGetWebcastsLazyQuery } from '../../../generated/graphql-manager';
import { useAbsoluteRoutes } from '../../../routes';
import { Filter } from '../../components/filter';
import { Search } from '../../components/search';
import { useCurrentUser } from '../../hooks/current-user';
import { getWebcastTitle } from '../../hooks/use-get-primary-title';
import { useUserPermissions } from '../../hooks/user-permissions';
import managerStyles from '../../manager.module.css';
import { NUMBER_OF_EVENTS, useUiState } from '../../providers/ui-state-provider/ui-state-provider';

import styles from './webcasts.module.css';

const PROGRESS_INDICATOR_TIMEOUT = 5000;
export const ONDEMAND_STATE = 'ONDEMAND' as State;

export function WebcastsPage() {
  const { t } = useTranslation();
  const { lsproId, userId } = useCurrentUser();
  const { isEventCreationAllowed } = useUserPermissions();
  const previousLsproId = usePrevious(lsproId);
  const routes = useAbsoluteRoutes();
  const { getNumberOfEvents, getEventsView, getEventsFilter, getEventsSorting, getEventsSearch, saveUiState } =
    useUiState();

  const FILTERS = [
    { value: StateFilter.PLANNED, label: t('manager.webcasts.filters.planned') },
    { value: StateFilter.LIVE, label: t('manager.webcasts.filters.live') },
    { value: StateFilter.ENDED, label: t('manager.webcasts.filters.ended') },
    { value: StateFilter.ON_DEMAND, label: t('manager.webcasts.filters.onDemand') },
    { value: userId, label: t('manager.webcasts.filters.myEvents') },
  ];

  const [webcastView, setWebcastView] = useState(getEventsView());
  const [search, setSearch] = useState(getEventsSearch());
  const [temporarySearch, setTemporarySearch] = useState(getEventsSearch());

  const [webcastIdToDelete, setWebcastIdToDelete] = useState<WebcastWithTitle['id']>('');
  const [webcastIdToDuplicate, setWebcastIdToDuplicate] = useState<WebcastWithTitle['id']>('');
  const [webcastTitleToDuplicate, setWebcastTitleToDuplicate] = useState<WebcastWithTitle['title']>('');
  const [duplicatingInProgressWebcastId, setDuplicatingInProgressWebcastId] = useState<WebcastWithTitle['id']>('');
  const [isProgressIndicatorVisible, setIsProgressIndicatorVisible] = useState(false);
  const [orderBy, setOrderBy] = useState(getEventsSorting());
  const [chosenFilters, setChosenFilters] = useState(getEventsFilter());

  const stateFilters = chosenFilters.filter((filter) => Object.values(StateFilter).includes(filter as StateFilter));
  const usersFilters = chosenFilters.filter((filter) => !Object.values(StateFilter).includes(filter as StateFilter));

  const [getWebcasts, getWebcastsResponse] = useGetWebcastsLazyQuery();

  const isRefetching = getWebcastsResponse.networkStatus === NetworkStatus.fetchMore;
  const isFetching = getWebcastsResponse.networkStatus === NetworkStatus.loading;

  const timeoutId = useRef<number>();
  const errorTimeoutId = useRef<number>();
  const searchTimeoutId = useRef(0);
  const isShowMoreEventsAvailable = getWebcastsResponse.data?.webcasts.pageInfo.hasNextPage;
  const edges = getWebcastsResponse.data?.webcasts.edges.map(({ node }) => node) || [];
  const webcasts = edges.map((webcast) => {
    const vodEnabled = webcast.streaming?.vod?.enabled;

    return {
      ...webcast,
      state: vodEnabled ? ONDEMAND_STATE : webcast.state,
      title: getWebcastTitle(webcast.primaryLanguage, webcast.contents),
    };
  });

  useEffect(() => {
    if (!lsproId) return;

    getWebcasts({
      variables: {
        lsproId,
        orderBy,
        first: getNumberOfEvents(),
        searchTerm: search,
        filterBy: { states: stateFilters as StateFilter[], users: usersFilters },
      },
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'cache-and-network',
    });
    // We don't want to refetch when numberOfEvents changes
  }, [getWebcasts, lsproId, orderBy, search, chosenFilters]); // eslint-disable-line

  useEffect(() => {
    if (lsproId !== previousLsproId) {
      getWebcastsResponse.refetch({ lsproId });
      handleCleanSearch();
      setChosenFilters([]);
    }
  }, [lsproId, previousLsproId, getWebcastsResponse]);

  useEffect(() => {
    if (isProgressIndicatorVisible && !duplicatingInProgressWebcastId) {
      timeoutId.current = window.setTimeout(() => {
        setIsProgressIndicatorVisible(false);
      }, PROGRESS_INDICATOR_TIMEOUT);
    } else {
      clearTimeout(timeoutId.current);
    }

    return () => {
      clearTimeout(timeoutId.current);
      clearTimeout(errorTimeoutId.current);
    };
  }, [duplicatingInProgressWebcastId, isProgressIndicatorVisible]);

  useEffect(() => {
    saveUiState({ key: 'eventsView', value: webcastView });
    saveUiState({ key: 'eventsSorting', value: orderBy });
    saveUiState({ key: 'eventsFilter', value: chosenFilters });
    saveUiState({ key: 'eventsSearch', value: search });
  }, [webcastView, orderBy, chosenFilters, search, saveUiState]);

  const duplicateWebcast = () => {
    setDuplicatingInProgressWebcastId(webcastIdToDuplicate);
    setWebcastIdToDuplicate('');
    setIsProgressIndicatorVisible(true);
  };

  const handleOnDuplicateWebcast = (id: Webcast['id'], title: WebcastWithTitle['title']) => {
    setWebcastIdToDuplicate(id);
    setWebcastTitleToDuplicate(title);
  };

  const handleWebcastDuplicatedError = () => {
    errorTimeoutId.current = window.setTimeout(() => {
      setDuplicatingInProgressWebcastId('');
      setIsProgressIndicatorVisible(false);
    }, PROGRESS_INDICATOR_TIMEOUT);
  };

  const handleLoadMoreEvents = () => {
    if (isRefetching) return;

    getWebcastsResponse.fetchMore({
      variables: { after: getWebcastsResponse.data?.webcasts.pageInfo.endCursor, first: NUMBER_OF_EVENTS },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev;

        const edges = [...prev.webcasts.edges, ...fetchMoreResult.webcasts.edges];

        saveUiState({ key: 'numberOfEvents', value: edges.length });

        return {
          webcasts: {
            ...fetchMoreResult.webcasts,
            edges,
          },
        };
      },
    });
  };

  const handleSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
    clearTimeout(searchTimeoutId.current);
    const searchValue = event.target.value;
    setTemporarySearch(searchValue);

    searchTimeoutId.current = window.setTimeout(() => setSearch(searchValue), 500);
  };

  const handleCleanSearch = () => {
    setSearch('');
    setTemporarySearch('');
  };

  const handleFilter = (filter: string) => {
    if (chosenFilters.includes(filter)) {
      setChosenFilters(chosenFilters.filter((f) => f !== filter));
    } else {
      setChosenFilters([...chosenFilters, filter]);
    }
  };

  const handleEventViewButtonClick = (view: EventsView) => {
    setWebcastView(view);
    saveUiState({ key: 'eventsScrollPosition', value: 0 });
  };

  return (
    <>
      <section className={classNames(managerStyles.section, styles.webcastsPage)}>
        <header style={{ flex: 'none' }}>
          <Search
            data-testid="webcasts-search"
            autoFocus
            placeholder={t('manager.webcasts.search.placeholder')}
            wrapperProps={{ className: styles.search }}
            value={temporarySearch}
            onChange={handleSearchChange}
            onClear={handleCleanSearch}
          />

          <button
            className={classNames(styles.viewSwitcher, webcastView === 'grid' && styles.active)}
            onClick={() => handleEventViewButtonClick('grid')}
            data-testid="grid-view-button"
          >
            <GridIcon />
            <Paragraph>{t('manager.webcasts.button.gridView')}</Paragraph>
          </button>

          <button
            className={classNames(styles.viewSwitcher, webcastView === 'list' && styles.active)}
            onClick={() => handleEventViewButtonClick('list')}
            data-testid="list-view-button"
          >
            <UnorderedListIcon />
            <Paragraph>{t('manager.webcasts.button.listView')}</Paragraph>
          </button>

          <Tooltip
            label={t('manager.webcastSetup.notEditableMessage.role')}
            hidden={isEventCreationAllowed}
            data-testid="create-new-event-button-tooltip"
          >
            <PrimaryButton
              component={NavLink}
              to={routes.webcastSetup_eventDetails}
              data-testid="create-new-event-button"
              disabled={!isEventCreationAllowed}
            >
              {t('manager.webcasts.newWebcastButton')}
              <PlusIcon />
            </PrimaryButton>
          </Tooltip>
        </header>

        <div className={styles.filters}>
          <Filter
            value=""
            data-testid="filter-all"
            closeIconNotVisible
            active={chosenFilters.length === 0}
            onClick={() => setChosenFilters([])}
          >
            {t('manager.webcasts.filters.all')}
          </Filter>

          {FILTERS.map((filter) => (
            <Filter
              key={filter.value}
              data-testid={`filter-${filter.value}`}
              value={filter.value}
              active={chosenFilters.includes(filter.value)}
              onClick={() => handleFilter(filter.value)}
            >
              {filter.label}
            </Filter>
          ))}
        </div>

        {!isFetching && webcastView === 'grid' && (
          <GridView
            webcasts={webcasts}
            isRefetching={isRefetching}
            onDeleteWebcast={setWebcastIdToDelete}
            onDuplicateWebcast={handleOnDuplicateWebcast}
          />
        )}

        {!isFetching && webcastView === 'list' && (
          <ListView
            webcasts={webcasts}
            isRefetching={isRefetching}
            onDeleteWebcast={setWebcastIdToDelete}
            onDuplicateWebcast={handleOnDuplicateWebcast}
            orderBy={orderBy}
            onSort={setOrderBy}
          />
        )}

        {isFetching && <Spinner size={40} style={{ margin: 'auto' }} />}

        {!isFetching && isShowMoreEventsAvailable && (
          <SecondaryButton
            data-testid="load-more-events-button"
            disabled={isRefetching}
            onClick={handleLoadMoreEvents}
            style={{ margin: '0 auto' }}
          >
            {isRefetching ? <Spinner /> : t('manager.webcasts.moreWebcastsButton')}
          </SecondaryButton>
        )}
      </section>

      <DeleteWebcastModal
        webcastId={webcastIdToDelete}
        isOpen={webcastIdToDelete !== ''}
        onClose={() => setWebcastIdToDelete('')}
      />

      <DuplicateWebcastModal
        isOpen={webcastIdToDuplicate !== ''}
        webcastTitle={webcastTitleToDuplicate}
        onDuplicate={duplicateWebcast}
        onClose={() => {
          setWebcastIdToDuplicate('');
          setWebcastTitleToDuplicate('');
        }}
      />

      {isProgressIndicatorVisible && (
        <DuplicationProgressIndicator
          duplicatedWebcastId={duplicatingInProgressWebcastId}
          duplicatedWebcastTitle={webcastTitleToDuplicate}
          onWebcastDuplicated={() => setDuplicatingInProgressWebcastId('')}
          onWebcastDuplicatedError={handleWebcastDuplicatedError}
        />
      )}
    </>
  );
}
