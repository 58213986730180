import { useEffect } from 'react';
import { useParams } from 'react-router';

import { useCurrentUserQuery } from '../../../generated/graphql-manager';
import { useUiState } from '../../providers/ui-state-provider/ui-state-provider';

export function useCurrentUser(channelId?: string) {
  const params = useParams();
  const CurrentUser = useCurrentUserQuery();
  const { getPreviousChannelId, saveUiState } = useUiState();

  const previousChannelId = getPreviousChannelId();
  const currentChannelId = channelId || params.channelId || previousChannelId;

  useEffect(() => {
    if (params.channelId) {
      saveUiState({ key: 'previousChannelId', value: params.channelId });
    }
  }, [params.channelId, saveUiState]);

  const currentUser = CurrentUser.data?.currentUser;
  const loading = currentUser === undefined;
  const accounts = currentUser?.accounts || [];
  const currentAccount = accounts.find((account) => account.liveStreamPro?.id === currentChannelId) || accounts[0];
  const userRoles = currentAccount?.userRoles || [];
  const isAdminUser = userRoles.some((role) => role === 'ADMIN');
  const isEditorUser = userRoles.some((role) => role === 'EDITOR');
  const isProducerUser = userRoles.some((role) => role === 'PRODUCER');
  const isModeratorUser = userRoles.some((role) => role === 'MODERATOR');
  const lsproId = currentAccount?.liveStreamPro?.id || '';
  const userId = currentUser?.id || '';
  const currentAccountName = currentAccount?.liveStreamPro?.name || '';
  const channelIdExists = accounts.some((account) => account.liveStreamPro?.id === currentChannelId);

  return {
    lsproId,
    userId,
    userRoles,
    isAdminUser,
    isEditorUser,
    isProducerUser,
    isModeratorUser,
    currentAccountName,
    loading,
    accounts,
    channelIdExists,
  };
}
